import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import sha256 from 'crypto-js/sha256';
import { useForm } from 'react-hook-form';

const ChangeAccountPasswordModal = ({managers, getLoggedManager, updatePass, toggle}) => {

  // hook form functions
  const { register, handleSubmit, getValues, errors } = useForm();

  const submitNewPass = (data) => {
        updatePass(getLoggedManager(), (sha256(data.npwd).toString()));
        alert("Sua senha foi alterada com sucesso.")
        toggle()
  }
  
    return (
        <Modal isOpen={true} modalTransition={{ timeout: 700 }} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()} id="modalHeader"><i className="fas fa-edit"></i>Alterar Palavra-passe</ModalHeader>
            
            <ModalBody id="modalBody">
            <div>
                <form name="chngpwd" action="" method="post" onSubmit={handleSubmit(submitNewPass)}>
                  <div className="form-group">
                    <label className="font-weight-bold">Insere a palavra-passe actual:</label>
                    <input className="form-control" type="password" name="opwd" ref={register({required: "Necessário preencher campo com a palavra-passe actual.",
                                                        validate: value => sha256(value).toString() === (managers.find(({id}) => id == getLoggedManager()).manager_password) || "A palavra passe inserida está incorrecta"})}></input>
                  </div>
                  {errors.opwd && <p className="login-errors">{errors.opwd.message}</p>}
                 
                  <div className="form-group">
                    <label className="font-weight-bold">Insere a nova palavra-passe:</label>
                    <input className="form-control" type="password" name="npwd" ref={register({ required: "Necessário preencher campo com a nova palavra-passe.", minLength: { value: 8, message: "Palavra-passe demasiado curta"}, maxLength: {value: 30, message: "Palavra-passe demasiado longa."}})}></input>
                  </div>
                  {errors.npwd && <p className="login-errors">{errors.npwd.message}</p>}

                  <div className="form-group">
                    <label className="font-weight-bold">Confirma a nova palavra-passe:</label>
                    <input className="form-control" type="password" name="cpwd" ref={register({required: "Necessário confirmar a nova palavra passe.", 
                                                                                validate: value => value === getValues().npwd || "A nova palavra passe e a sua confirmação não condizem. Tente novamente."})}></input>
                  </div>
                  {errors.cpwd && <p className="login-errors">{errors.cpwd.message}</p>}

                  <button type="submit" name="Submit" className="btn btn-dark">Enviar</button>
                </form>
            </div>
            </ModalBody>
        </Modal>
    )
}

export default ChangeAccountPasswordModal;