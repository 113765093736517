import React from 'react';
import { Nav, NavItem, NavLink, ToastHeader } from 'reactstrap';
import classnames from 'classnames';
import './SuggestionsFilter.css';

const SuggestionsFilter = ({ tabs, activeTab, toggle }) => {

    // render tabs
    const tabsList = tabs.map(tab => {

        return (
            <NavItem className="suggestion-tab-item" key={tab.id}>
                <NavLink
                    className={classnames({ active: activeTab === tab.name })}
                    onClick={() => { toggle(tab.name); }}
                    id="suggestion-tab-link" >
                    <ToastHeader icon={tab.iconColor} className="suggestion-tab-header">{tab.name}</ToastHeader>
                </NavLink>
            </NavItem>
        )
    });

    return (
        <div className="suggestion-filter">
            <Nav tabs className="suggestion-filter-tabs">
                {tabsList}
            </Nav>
        </div>
    );
}

export default SuggestionsFilter;