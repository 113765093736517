import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import TablePagination from '../../components/Pagination/TablePagination';
import './OperatorsList.css';

const OperatorsList = ({operatorsX, getOperators, deleteOperator}) => {

const [currentPage, setCurrentPage] = useState(0);
const pageSize = 8;

    useEffect(() => {getOperators();}, []);

    // List of operators
    const operatorsList  = () => {

        return paginate(operatorsX).map((
            {
              input,
              operator,
              keycode,
              created_at,
              deleted_at
            }) => {
              
              if (deleted_at == null) {
              
                return (
                  <tr key={input}>
                    <td scope="row" data-label="Nome">{operator}</td>
                    <td data-label="Código do operador">{keycode}</td>
                    <td data-label="Data de registo">{created_at}</td>
                    <td data-label="Deletar"><button className="btn btn-dark btn-sm" onClick={() => deleteOperator(input)}><i className="fas fa-user-minus"></i></button></td>
                  </tr>
                )
              }
          });
        }

    const paginate = (operators) => {
        const page = currentPage;
            return [...operators].splice(page * pageSize, pageSize);
    }

    // render table
    const operatorsTable = () => {

    if(operatorsX.requestPending) {
      return (
        <div className="table__spinner">
         <span className="spinner-border"></span>
         <span className="spinner__text">A carregar...</span>
        </div>
      )
    } 

    if (operatorsX.length === 0) {

      return (
        <p>Ainda não há operadores de recolha registados.</p>
      )
    }

  return(
    <Table>
      <thead className="operators-table-head">
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">Código do operador</th>
          <th scope="col">Data de registo</th>
          <th scope="col">Deletar</th>
        </tr>
      </thead>

      <tbody className="operators-table-body">
          {operatorsList()}
      </tbody>
    </Table>
  )
}

    const renderPagination = () => {
        if (operatorsX.requestPending) {
        return; 
        } if (operatorsX.length <= pageSize) {
        return;
        }
        return (<TablePagination pageSize={pageSize} numRows={operatorsX.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
    }

    return (
        <div className="operators-list">
             <label className="operators-table-title"> Operadores de recolha registados</label>
             {operatorsTable()}
             {renderPagination()}
        </div>
    )

}

export default OperatorsList;