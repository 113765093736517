import React, { createContext, useContext, useState } from 'react';
import { useRequestsContext } from './RequestsContext';
import Axios from 'axios';

const UserHistoryContext = createContext();

export default function UserHistoryProvider ({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    const fetchRequest = requestPrefix + "/management-user-history/read-user-history.php";

    const [userHistory, setUserHistory] = useState({requestPending:true});

    // FETCH INFO FROM THE DATABASE
    const fetchUserHistory = (id, initialDate, finalDate) => {
        Axios.post(fetchRequest,
            {
                id: id,
                initialDate: initialDate,
                finalDate: finalDate
            })
            .then(({ data }) => {
              
                if (data?.success === 1) {
                    
                    setUserHistory(data.userHistory)

                }  else {

                    alert(data.msg);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const userHistoryValue = {
        userHistoryX: userHistory,
        getUserHistory: fetchUserHistory,
    }

    return (
        <UserHistoryContext.Provider value={userHistoryValue}>
            {children}
        </UserHistoryContext.Provider>
    )
}

export const useUserHistoryContext = () => {
    const userHistoryContext = useContext(UserHistoryContext);

    if (!userHistoryContext) {
        throw new Error("useUserHistoryContext must be used within a UserHistoryProvider");
    }
    return userHistoryContext;
}
