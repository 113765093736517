import React, { useState } from 'react';
import { useWindowContext } from '../../../context/WindowContext';
import AlertsContent from './AlertsContent';
import AlertsFilter from './AlertsFilter';
import './Alertas.css';

const Alertas = (props) => {

  const [date, setDate] = useState(null);

  // window context
  const {
    windowSize,
  } = useWindowContext();

  // filter tabs state
  const [tabs, setTabs] = useState([
    { name: "Todos", id: 1, iconColor: "dark" },
    { name: "Contentores cheios", id: 2, iconColor: "warning" },
    { name: "Contentores danificados", id: 3, iconColor: "danger" },
    { name: "Recolhas especiais", id: 4, iconColor: "primary"}, 
    { name: "Alertas resolvidos", id: 5, iconColor: "success" },
    { name: "Rec. especiais realizadas", id: 6, iconColor: "success"}
    //{ name: "Resíduos Perigosos", id: 6, iconColor: "danger" }
  ]);

  // state for active tab
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  // show alerts state
  const [buttonClicked, setButtonClicked] = useState(false);

  // set active tab function
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

   // arrow up or down
   const renderCaret = () => {  
    return (
    <>
        {!buttonClicked && <i className="fas fa-caret-down"></i>}
        {buttonClicked && <i className="fas fa-caret-up"></i>} 
    </>
    ); 
  }

   // show content (tabs)
   const handleClick = () => {
    setButtonClicked(!buttonClicked);
  }

  const renderAlertsTabs = () => {
    if (buttonClicked) {
      return (<AlertsFilter tabs={tabs} activeTab={activeTab} toggle={toggle} setDate={setDate}/>)
    }
  }

  if (windowSize.width <= 850) {

    return (
      <div className="alertas">
        <div className="responsiveAlertsMobile">
        <button className="open-tabs-button" onClick={handleClick}> Filtrar alertas {renderCaret()}</button>
        {renderAlertsTabs()}
        </div>
        <label className="alerts-title-resp">Alertas</label>
        <AlertsContent tabs={tabs} activeTab={activeTab} date={date} className="alerts-content"/>
      </div>
    )
  }

  return (
    <div className="alertas" >
      <AlertsContent tabs={tabs} activeTab={activeTab} date={date} className="alerts-content"/>
      <AlertsFilter tabs={tabs} activeTab={activeTab} toggle={toggle} setDate={setDate}/>
    </div>
  );
};

export default Alertas;