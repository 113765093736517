import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Side-nav.css';
import './Hamburger.css';

const SideBar = ({ isOpen, toggleSidebar }) => {

    // Hamburguer button state
    const [toggleButton, setToggleButton] = useState(
        { name: 'Dashboard', id: 1, icon: 'fa fa-dashboard' }
    );

    // Links State
    const [sideButtons, setSideButtons] = useState([
        { name: 'ALERTAS', id: 1, icon: 'fas fa-bell', url: '/dashboard/', disabled: false },
        { name: 'CONTENTORES', id: 2, icon: 'fas fa-dumpster', url: '/dashboard/contentores', disabled: false },
        { name: 'LOCALIZAÇÃO', id: 3, icon: 'fas fa-map-marker-alt', url: '/dashboard/localizaçao', disabled: false },
        { name: 'RECOLHAS', id: 4, icon: 'fas fa-truck-loading', url: '/dashboard/recolhas', disabled: false },
        { name: 'UTILIZADORES', id: 5, icon: 'fas fa-users', url: '/dashboard/utilizadores', disabled: false },
        { name: 'SUGESTÕES', id: 6, icon: 'fas fa-lightbulb', url: '/dashboard/sugestoes', disabled: false },
        { name: 'MENSAGENS', id: 7, icon: 'fas fa-inbox', url: '/dashboard/mensagens', disabled: false },
        { name: 'PRÉMIOS', id: 8, icon: 'fas fa-trophy', url: '/dashboard/premios', disabled: false },
        /* { name: 'HISTÓRICO', id: 9, icon:'fas fa-chart-bar', url: '/dashboard/historico', disabled: false },
        { name: 'PATROCINADORES', id: 8, icon: 'fas fa-handshake', url: '/dashboard/patrocinadores', disabled: true },
        { name: 'CAMPANHAS', id: 9, icon: 'fas fa-newspaper', url: '/dashboard/campanhas', disabled: true },
        { name: 'MANUTENÇÃO', id: 10, icon: 'fas fa-tools', url: '/dashboard/manutenção', disabled: true },
        { name: 'RESÍDUOS', id: 11, icon: 'fas fa-recycle', url: '/dashboard/resíduos', disabled: true },
        { name: 'ACESSOS', id: 12, icon: 'fas fa-truck', url: '/dashboard/acessos', disabled: true } */
        ]);

    // Change hamburguer className on open/close sidebar
    const hamburgerClass = isOpen ? 'hamburger hamburger--arrow is-active' : 'hamburger hamburger--arrow';
    
    // Change sidebar className on open/close sidebar
    const sidebarClass = isOpen ? 'sidebar-full' : 'sidebar-hidden';
    
    // Change iconsize on open/close sidebar
    const iconSize = isOpen ? ' fa-lg' : ' fa-2x';

    // render dashboard and hamburguer/arrow button
    const dashboardItem = () => {

        return (
            <div id="dashboard-item">
                <span id="button-box">
                    <button className={hamburgerClass} id="hamburger-button"
                        type="button" onClick={toggleSidebar}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </span>
                <span id="dashboard-icon">
                    <i className={toggleButton.icon + " fa-lg"}></i>
                </span>
                <span id="dashboard-text">
                    {toggleButton.name}
                </span>
            </div>
        )
    };

    // render nav links
    const buttonsList = sideButtons.map(button => {

        const disabledLinkStyle = {
            cursor: "default",
        };

        if (button.disabled) {

            return (
                <div className={sidebarClass + " disabled-link"}
                    id="side-link" key={button.id}
                    style={disabledLinkStyle} >

                    <div id="inner-link" >
                        <span id="side-icon">
                            <i className={button.icon + iconSize}></i>
                        </span>
                        <span id="side-link-text">
                            {button.name}
                        </span>
                    </div>

                </div>
            )
        }

        return (
            <NavLink
                exact to={button.url}
                className={sidebarClass}
                activeClassName="active-link"
                id="side-link" key={button.id} >

                <div id="inner-link" >
                    <span id="side-icon">
                        <i className={button.icon + iconSize}></i>
                    </span>
                    <span id="side-link-text">
                        {button.name}
                    </span>
                </div>
                
            </NavLink>
        )
    });

    return (
        <div className={sidebarClass} id="sidebar" >
            {dashboardItem()}
            {buttonsList}
        </div>
    );
}

export default SideBar;