import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import { useLoginContext } from './LoginContext';
import Axios from 'axios';

const MessagesContext = createContext();

export default function MessagesProvider({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    // Login Context
    const {
        getLoggedManager
    } = useLoginContext();

    const fetchRequest = requestPrefix + "/management-messages/read-messages.php";
    const insertRequest = requestPrefix + "/management-messages/add-message.php"
    const deleteRequest = requestPrefix  + "/management-messages/delete-message.php"
    const uploadRequest = requestPrefix + "/files/upload-file.php"

    const [messages, setMessages] = useState({requestPending:true});


    // FETCH MESSAGES FROM THE DATABASE
    const fetchMessages = () => {

        Axios.get(fetchRequest)
            .then(({ data }) => {
             
                if (data.success === 1) {
                    const messages = [];
                    data.messages.forEach(element => {
                        if(messages.some((message) => message.input === element.input)) {
                                let msg = messages.find(message => message.input === element.input);
                                msg.allUsers = true;
                                delete msg.first_name;
                                delete msg.last_name;
                                return;
                        }
                        messages.push(element);
                    });
                    setMessages(messages);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // ADD NEW MESSAGE TO DB
    const insertMessageDB = (event, user_id, title, message, filePath) => {
        Axios.post(insertRequest,
            {
                manager_id: parseInt(getLoggedManager()),
                user_id: user_id,
                title: title,
                message: message,
                filePath: filePath || "null"
            })
            .then(({ data }) => {
                
                if (data.success === 1) {

                    let messagesArr = [...messages];
                    messagesArr.push({
                        input: data.id,
                        user_id: user_id,
                        title: title,
                        message: message
                    });

                    setMessages(messagesArr);

                    event.target.reset();

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    const uploadImage = (file) => {
        var formData = new FormData();
        formData.append("file", file[0]);
        return Axios.post(uploadRequest, formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
    }

    // SEND NEW MESSAGE - WITH OR WITHOUT ATTACHMENT
    const insertMessage = (event, user_id, title, message, file) => {

        event.preventDefault();
        event.persist();
    
            if (file[0]) {
                uploadImage(file).then(({ data: uploadedData }) => {
                if (uploadedData.success === 0) {
                    alert(uploadedData.msg);
                    return;
                }  
                insertMessageDB(event, user_id, title, message, uploadedData.file);
            });  
            } else {
                insertMessageDB(event, user_id, title, message);
            }    
    }

    // DELETE MESSAGE FROM THE DB 
    const deleteMessage = (id) => {
        
        let messagesArr = messages.filter(message => message.input !== id);

        Axios.post(deleteRequest,
            {
                id: id
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    setMessages(messagesArr);

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // SEE THE ENTIRE MESSAGE
    const seeTheEntireMessage = (id) => {

        let messagesArr = messages.map(message => {

            if (message.input === id) {

                message.seeTheWholeMessage = message.seeTheWholeMessage === true ? false : true;

                return message;
            }

            message.seeTheWholeMessage = false;

            return message;
        });

        setMessages(messagesArr);
    }

    const messagesValue = {
        messagesX: messages,
        setMessagesX: setMessages,
        getMessages: fetchMessages,
        insertMessage: insertMessage,
        seeTheEntireMessage: seeTheEntireMessage,
        deleteMessage: deleteMessage
    }

    return (
        <MessagesContext.Provider value={messagesValue}>
            {children}
        </MessagesContext.Provider>
    )
}

export const useMessagesContext = () => {

    const messagesContext = useContext(MessagesContext);

    if (!messagesContext) {

        throw new Error("useMessagesContext must be used within a MessagesProvider");
    }

    return messagesContext;
}