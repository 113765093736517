import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useUsersContext } from '../../../context/UsersContext';
import TablePagination from '../../Pagination/TablePagination';
import './SpecialAlerts.css';

const SpecialAlerts = ({ tabType, seeTheEntireMessageSpecialAlerts, resolveSpecialAlert, specialAlerts, readSpecialAlerts }) => {

    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 7;
    
    const [imagePath, setImagePath] = useState(null);

    // user context - to get users info
    const {
        usersX,
        getUsers,
    } = useUsersContext();

    useEffect(() => {
        readSpecialAlerts();
        getUsers();
    }, []);

    let getFilteredAlerts = () => {
        console.log("entering getfilteredalerts, state: " , specialAlerts)
        if (!Array.isArray(specialAlerts)) {
            return specialAlerts;
        }
        if (tabType === "Rec. especiais realizadas") {
           return specialAlerts.filter(({resolved}) => resolved == 1)
        } 
        
        if (tabType === "Recolhas especiais") {
            return specialAlerts.filter(({resolved}) => resolved == 0)
        }
    }

    //function to resolved (boolean) appear as 'yes' or 'no' on the table
    const booleanToString = (resolved) => {
        if (resolved == false) {
        return "não"
        } else {
            return "sim"
        }
    }

    //function to get user Name instead of id
    const userName = (userId) => {
        if (usersX.requestPending) {
            return;
        }
        if (usersX.length < 1) return;
        const user = usersX.find(({id}) => id === userId)
    
        const first_name = user?.first_name;
        const last_name = user?.last_name;
        return (<td data-label="Utilizador">{first_name} {last_name}</td>)
    }

    // modal state, toggle modal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const toggleImg = (img) => {
    setImagePath(img);
    toggle();
    }

    //render attachment (image file) when click button
    const modalImage = () => {

    return (
      <div>
      <Modal isOpen={modal} modalTransition={{ timeout: 700 }} toggle={toggle} className="modalTest">
        <ModalHeader toggle={toggle} id="modalHeader">Imagem anexada pelo utilizador</ModalHeader>
            <ModalBody id="modalBody">
              <img src={"https://www.smartbiowaste.pt/" + imagePath} width="300" height="300" />
            </ModalBody>
      </Modal>
      </div>
    )
  }

  const generateActionName = (actionId) => {
    switch(actionId) {
        case "1":
            return "Resíduos verdes";
        case "2":
            return "Resíduos perigosos";
        case "3":
            return "Resíduos de Equipamentos Eletrónicos";
        case "4":
            return "Monstros"; 
        case "5": 
            return "Resíduos de Construção e Demolição";
        case "6":
            return "Outros";
    }
  }

// render special alerts list
const specialAlertsList = () => {
    
    return getFilteredAlerts().map((
      {
        id,
        action_id,
        message,
        image,
        userId,
        resolved,
        createdAt,
        seeAllMsg
      }) => {
        
        return seeAllMsg ?
        (
          <>
          <tr key={id} className="table-specialAlerts-body">
              <td data-label="Data" scope="row">{createdAt}</td>
              {userName(userId)}
              <td data-label="Tipo de recollha">{generateActionName(action_id)}</td>
              <td className="emptyrow-alerts"></td>
              <td data-label="Resolvido">{booleanToString(resolved)}</td>
              <td data-label="Ações">
                <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessageSpecialAlerts(id)}}><i className="fas fa-eye"></i></button>
                <button type="button" className="btn" title="ver anexo" onClick={() => toggleImg(image)} disabled={image === null || image == ""}><i className="fas fa-image"></i></button>
                <button type="button" className="btn" title="resolver alerta" disabled={resolved == true} onClick={() => resolveSpecialAlert(id)}><i className="fas fa-check"></i></button>
              </td>
          </tr>
          <tr><td className="colspan-style" colSpan="6" data-label="Mensagem">{message}</td></tr>
          </>
        ) : (
            <tr key={id} className="table-specialAlerts-body">
              <td data-label="Data" scope="row">{createdAt}</td>
              {userName(userId)}
              <td data-label="Tipo de recollha">{generateActionName(action_id)}</td>
              <td className="content hideContent" data-label="Mensagem">{message}</td>
              <td data-label="Resolvido">{booleanToString(resolved)}</td>
              <td data-label="Ações">
                <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessageSpecialAlerts(id)}}><i className="fas fa-eye"></i></button>
                <button type="button" className="btn" title="ver anexo" onClick={() => toggleImg(image)} disabled={image === null || image == ""}><i className="fas fa-image"></i></button>
                <button type="button" className="btn" title="resolver alerta" disabled={resolved == true} onClick={() => resolveSpecialAlert(id)}><i className="fas fa-check"></i></button>
              </td>
          </tr>
        )
      });
  } 

  // render alerts table
  const specialAlertsTable = () => {
    let filteredAlerts = getFilteredAlerts();
  if(filteredAlerts.requestPending) {
      return (
        <div className="table__spinner">
        <span className="spinner-border"></span>
        <span className="spinner__text">A carregar...</span>
        </div>
      )
    }

    if (filteredAlerts.length < 1) {

      return (
        <p>Não há pedidos de recolhas especiais.</p>
      )
    }

  return(
    <Table>
      <thead className="table-alerts-head">
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Utilizador</th>
          <th scope="col">Tipo de recolha</th>
          <th scope="col">Mensagem</th>
          <th scope="col">Resolvido</th>
          <th scope="col">Ações</th>
        </tr>
      </thead>

      <tbody>
          {specialAlertsList()}
      </tbody>
    </Table>
  )
}

 const renderPagination = () => {
    let filteredAlerts = getFilteredAlerts();
  if (filteredAlerts.requestPending) {
    return;
  } 
  
  if (filteredAlerts.length <= pageSize) {
    return;
  }
  return (<TablePagination pageSize={pageSize} numRows={filteredAlerts.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
}

  return (
    <div className="alerts-list">
        <div>
          {specialAlertsTable()}   
          {renderPagination()}
        </div>
      {modalImage()}
    </div>
  );
}

export default SpecialAlerts;