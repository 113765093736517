import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const ChangeAccountNameModal = ({getLoggedManager, updateName, toggle}) => {

    // hook form functions
    const { register, handleSubmit, errors } = useForm();
    
  
      const submitNewName = (data) => {
            updateName(getLoggedManager(), data.first_name, data.last_name);
            alert("Dados alterados com sucesso.")
            toggle()
        }
  
  
      return (
          <Modal isOpen={true} modalTransition={{ timeout: 700 }} toggle={() => toggle()}>
              <ModalHeader toggle={() => toggle()} id="modalHeader"><i className="fas fa-edit"></i>Alterar nome</ModalHeader>
              
              <ModalBody id="modalBody">
              <div>
                  <form action="" method="post" onSubmit={handleSubmit(submitNewName)}>
                    <div className="form-group">
                      <label className="font-weight-bold">Insere o primeiro nome:</label>
                      <input className="form-control" type="text" name="first_name"  ref={register({required: "Obrigatório preencher este campo", maxLength: {value: 20, message: "Máximo de 20 caracteres."}})}></input>
                    </div>
                    {errors.first_name && <p className="form-changename-errors">{errors.first_name.message}</p>}

                    <div className="form-group">
                      <label className="font-weight-bold">Insere o último nome:</label>
                      <input className="form-control" type="text" name="last_name"  ref={register({required: "Obrigatório preencher este campo", maxLength: {value: 20, message: "Máximo de 20 caracteres."}})}></input>
                    </div>
                    {errors.last_name && <p className="form-changename-errors">{errors.last_name.message}</p>}
  
                    <button type="submit" name="Submit" className="btn btn-dark">Enviar</button>
                  </form>
              </div>
              </ModalBody>
          </Modal>
      )
  }
  
  export default ChangeAccountNameModal;