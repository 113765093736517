import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import Axios from 'axios';

const SuggestionsContext = createContext();

export default function SuggestionsProvider({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    const fetchRequest = requestPrefix + "/management-suggestions/read-suggestions.php";
    const updateSuggestionRequest = requestPrefix + "/management-suggestions/update-suggestion.php";

    const [suggestions, setSuggestions] = useState({requestPending:true});

    // FETCH SUGGESTIONS FROM THE DATABASE
    const fetchSuggestions = () => {

        Axios.get(fetchRequest)
            .then(({ data }) => {

                if (data.success === 1) {

                    setSuggestions(data.suggestions);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

     // SEE THE ENTIRE MESSAGE
     const seeTheEntireMessage = (id) => {

        let suggestionsArr = suggestions.map(suggestion => {

            if (suggestion.id === id) {

               suggestion.seeAllMsg = suggestion.seeAllMsg === true ? false : true;

                return suggestion;
            }

            suggestion.seeAllMsg = false;

            return suggestion;
        });

        setSuggestions(suggestionsArr);
    }

    // VALIDATE/ACCEPT SUGGESTION
    const validateSuggestion = (id) => {
        Axios.post(updateSuggestionRequest, 
            {
                id: id,
                accepted: true,
                resolved: "0"
            }
        ).then(({data}) => {
            if (data?.success === 1) {
               setSuggestions (suggestions.map(suggestion => {
                    if(id === suggestion.id) {
                        suggestion.accepted = true;
                        suggestion.resolved = false;
                    }
                    return suggestion;
                }))
            }
        })
    }

    // DISCARD SUGGESTION
    const discardSuggestion = (id) => {
        Axios.post(updateSuggestionRequest, 
            {
                id: id,
                accepted: "0",
                resolved: true
            }
        ).then(({data}) => {
            if (data?.success === 1) {
               setSuggestions (suggestions.map(suggestion => {
                    if(id === suggestion.id) {
                        suggestion.accepted = false;
                        suggestion.resolved = true;
                    }
                    return suggestion;
                }))
            }
        })
    }

    // SUGGESTION DONE (RESOLVED)
    const resolveSuggestion = (id) => {
        Axios.post(updateSuggestionRequest, 
            {
                id: id,
                accepted: true,
                resolved: true
            }
        ).then(({data}) => {
            if (data?.success === 1) {
               setSuggestions (suggestions.map(suggestion => {
                    if(id === suggestion.id) {
                        suggestion.accepted = true;
                        suggestion.resolved = true;
                    }
                    return suggestion;
                }))
            }
        })
    }

    const suggestionsValue = {
        suggestionsX: suggestions,
        getSuggestions: fetchSuggestions,
        seeTheEntireMessage: seeTheEntireMessage,
        validateSuggestion: validateSuggestion,
        discardSuggestion: discardSuggestion,
        resolveSuggestion: resolveSuggestion
    }

    return (
        <SuggestionsContext.Provider value={suggestionsValue}>
            {children}
        </SuggestionsContext.Provider>
    )
}

export const useSuggestionsContext = () => {

    const suggestionsContext = useContext(SuggestionsContext);

    if (!suggestionsContext) {

        throw new Error("useSuggestionsContext must be used within a SuggestionsProvider");
    }

    return suggestionsContext;
}