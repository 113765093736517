import React, {useState} from 'react';
import './Account.css';
import ChangeAccountPasswordModal from './modals/ChangePassword'
import ChangeAccountPictureModal from './modals/ChangePicture'
import ChangeAccountNameModal from './modals/ChangeName'


const Profile = ({requestPrefix, managers, getLoggedManager, updatePass, updatePicture, updateName}) => {

    // modal to show edit prizes
    const [modal, setModal] = useState(null);
    const toggle = (modalId) => {
        if (modalId) {
            setModal(modalId)
        } else {
            setModal(null)
        }
    }

    const showModal = () => {
        if(modal === "pictureModal") {
            return(<ChangeAccountPictureModal toggle={toggle} managers={managers} getLoggedManager={getLoggedManager} requestPrefix={requestPrefix} updatePicture={updatePicture}/>)
        }
        if(modal === "passwordModal") {
            return (<ChangeAccountPasswordModal toggle={toggle} managers={managers} getLoggedManager={getLoggedManager} updatePass={updatePass}/>)
        }
        if (modal === "nameModal") {
            return (<ChangeAccountNameModal toggle={toggle} managers={managers} getLoggedManager={getLoggedManager} updateName={updateName} />)
        }
    } 

    return (
        <>
            <div className="account-profile-content">
                <p className="titles-style">{managers.find(({id}) => id == getLoggedManager()).manager_firstname} {managers.find(({id}) => id == getLoggedManager()).manager_lastname}</p>
                <img src={requestPrefix + "/" + managers.find(({id}) => id == getLoggedManager()).manager_picture} alt="Avatar" id="profile-pic-account" /><br></br>
                <div className="form-group row account-email-box">
                    <label className="col-form-label font-weight-bold">Email: </label>
                    <input className="col form-control" type="text" value={managers.find(({id}) => id == getLoggedManager()).manager_email} disabled></input>
                </div>
                <div className="profile__actions">
                    <button className="btn btn-dark profile__actions--change-name" onClick={() => toggle("nameModal")}>Alterar nome</button>
                    <button className="btn btn-dark profile__actions--change-picture" onClick={() => toggle("pictureModal")}>Alterar Foto</button>
                    <button className="btn btn-dark profile__actions--change-password" onClick={() => toggle("passwordModal")}>Alterar palavra-passe</button>
                </div>
            </div>
            {showModal()}
        </>
      )
}

export default Profile;