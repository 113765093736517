import React, {useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Table } from 'reactstrap';
import sha256 from 'crypto-js/sha256';
import { useAccountContext } from '../context/AccountContext';

const CreateNewAccount = ({managers, getManagers}) => {

  // hook form functions
  const { register, getValues, handleSubmit, errors } = useForm();

  // show 'add new account' form state
  const [addClicked, setAddClicked] = useState(false);

    // show 'add new account' form
    const handleAddClick = () => {
      setAddClicked(!addClicked);
  }

  useEffect(() => {
    getManagers()
  }, [])

  // account context
  const {
    createNewAccount,
    deleteAccount
  } = useAccountContext();

  const submitNewAcc = (data, event) => {
    if (document.getElementById("checkbox1").checked) {
      data.admin = true
    } else {
      data.admin = "0"
    }
    createNewAccount(event, data.managerEmail, (sha256(data.managerPassword).toString()), data.managerFirstName, data.managerLastName, data.admin);
    handleAddClick();
  }

   // arrow up or down in "add new account button"
   const renderCaret = () => {  
    return (
    <>
        {!addClicked && <i className="fas fa-caret-down"></i>}
        {addClicked && <i className="fas fa-caret-up"></i>} 
    </>
    ); 
}

  // Form to create new account
  const addNewAccForm = () => {

    // check if add button has been clicked
    if (!addClicked) {
      return (null);
    }

    return (
      <div className="newAcc__form">
        <form method="post" onSubmit={handleSubmit(submitNewAcc)}>
          <div className="form-group">
            <label className="font-weight-bold">Email</label>
            <input name="managerEmail" type="text" className="form-control" ref={
                                                                      register({
                                                                          required: "Necessário preencher campo 'email'",
                                                                          pattern: { value: /^\S+@\S+\.\S+$/i, message: "Email inválido"}, 
                                                                          validate: email => !managers.some(({manager_email}) => manager_email == email) || "Email já registado."
                                                                      })}/>
          </div>
          {errors.managerEmail && <p className="newacc-form-errors">{errors.managerEmail.message}</p>}

          <div className="form-group">
            <label className="font-weight-bold">Palavra-passe</label>
            <input className="form-control" type="password" name="managerPassword" ref={register({ required: "Necessário preencher campo 'palavra-passe'", 
                                                                                              minLength: { value: 8, message: "Palavra-passe demasiado curta"}})}></input>
          </div>
          {errors.managerPassword && <p className="newacc-form-errors">{errors.managerPassword.message}</p>}


          <div className="form-group">
            <label className="font-weight-bold">Confirma a palavra-passe</label>
            <input className="form-control" type="password" name="confirmPassword" ref={register({ required: "Necessário preencher campo 'confirmação da palavra-passe'", 
                                                                                  minLength: { value: 8, message: "Palavra-passe demasiado curta"},
                                                                                  validate: value => value === getValues().managerPassword || "A palavra passe e a sua confirmação não condizem. Tente novamente."
                                                                                })}></input>
          </div>
          {errors.confirmPassword && <p className="newacc-form-errors">{errors.confirmPassword.message}</p>}

          <div className="form-group">
            <label className="font-weight-bold">Primeiro nome do colaborador</label>
            <input name="managerFirstName" type="text" className="form-control" ref={register({ required: "Necessário preencher campo 'primeiro nome'",})}></input>
          </div>
          {errors.managerFirstName && <p className="newacc-form-errors">{errors.managerFirstName.message}</p>}

          <div className="form-group">
            <label className="font-weight-bold">Último nome do colaborador</label>
            <input name="managerLastName" type="text" className="form-control" ref={register({ required: "Necessário preencher campo 'último nome'",})}></input>
          </div>
          {errors.managerLastName && <p className="newacc-form-errors">{errors.managerLastName.message}</p>}

          <div className="form-group">
            <label className="form-check-label font-weight-bold" for="checkbox1">Administrador</label>
            <input type="checkbox" className="form-check-input" id="checkbox1"/>
          </div>

          <div className="create-newacc-button">
            <button type="submit" name="Submit" className="btn btn-dark">Criar</button>
          </div>
      </form>
    </div>
    )
  }

  const managersTable = () => {

  if (managers.length === 0) {

      return (
          <p>Não existem colaboradores registados.</p>
      )
  }

  return (
      <Table className="managers-table">
          <thead className="managers-table-head">
              <tr>
                  <th scope="col">Id do colaborador</th>
                  <th scope="col">Email</th>
                  <th scope="col">Nome do colaborador</th>
                  <th scope="col">Administrador</th>
                  <th scope="col">Deletar</th>
              </tr>
          </thead>
          <tbody className="managers-table-body">
              {managersTableBody()}
          </tbody>
      </Table>
  )
}

const managersTableBody = () => {
  return managers.map((
    {
      id,
      manager_email,
      manager_firstname,
      manager_lastname,
      admin
    }) => {
      
      return (
        <tr key={id} className="managers-table-row">
                    <td scope="row" data-label="Id do colaborador">{id}</td>
                    <td data-label="Email">{manager_email}</td>
                    <td data-label="Nome do colaborador">{manager_firstname} {manager_lastname}</td>
                    <td data-label="Administrador">{booleanToString(admin)}</td>
                    <td data-label="Deletar"><button className="btn btn-dark btn-sm" onClick={() => deleteAccount(id)} disabled={id == 1}><i className="fas fa-user-minus"></i></button></td>
                </tr>
      )
  });
}

const booleanToString = (admin) => {
  if (admin == 1 || admin == true) {
    return "sim"
  } else {
    return "não"
  }
}

return (
  <div className="newAcc">
      <button className="newacc-add-button" onClick={handleAddClick}><i className="fas fa-user-friends"></i> Criar nova conta {renderCaret()}</button>
      {addNewAccForm()}

      <div className="managers__table">
      <label className="registered-managers-table"><i className="fas fa-users"></i> Colaboradores registados</label>
        {managersTable()}       
      </div>
  </div>
)
}

export default CreateNewAccount;