import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import Axios from 'axios';

const AlertsContext = createContext();

export default function AlertsProvider({ children }) {
    // Requests Context
    const { requestPrefix } = useRequestsContext();

    const fetchRequest = requestPrefix + '/management-alerts/read-alerts.php';
    const resolveAlertRequest = requestPrefix + '/management-alerts/resolve-alert.php';
    const resolveSpecialAlertReq =
        requestPrefix + '/management-alerts/resolve-special-alert.php';

    const [alerts, setAlerts] = useState({ requestPending: true });
    const [specialAlerts, setSpecialAlerts] = useState({ requestPending: true });

    // FETCH ALERTS FROM THE DATABASE
    const fetchAlerts = () => {
        Axios.get('https://smartbiowaste.pt/api/action/read.php').then((res) => {
            if (res.data.success === 1) {
                setAlerts(res.data.alerts.reverse());
            }
        });
    };

    // SEE THE ENTIRE MESSAGE
    const seeTheEntireMessage = (id) => {
        let alertsArr = alerts.map((alert) => {
            if (alert.id === id) {
                alert.seeAllMsg = alert.seeAllMsg === true ? false : true;

                return alert;
            }

            alert.seeAllMsg = false;

            return alert;
        });

        setAlerts(alertsArr);
    };

    // SEE THE ENTIRE MESSAGE - SPECIAL ALERTS
    const seeTheEntireMessageSpecialAlerts = (id) => {
        let specialAlertsArr = specialAlerts.map((specialAlert) => {
            if (specialAlert.id === id) {
                specialAlert.seeAllMsg = specialAlert.seeAllMsg === true ? false : true;

                return specialAlert;
            }

            specialAlert.seeAllMsg = false;

            return specialAlert;
        });

        setSpecialAlerts(specialAlertsArr);
    };

    // RESOLVE ALERT
    const resolveAlert = (id) => {
        Axios.post(resolveAlertRequest, {
            id: id,
        }).then(({ data }) => {
            if (data?.success === 1) {
                setAlerts(
                    alerts.map((alert) => {
                        if (id === alert.id) {
                            alert.resolved = true;
                        }
                        return alert;
                    })
                );
            }
        });
    };

    // RESOLVE SPECIAL ALERT
    const resolveSpecialAlert = (id) => {
        Axios.post(resolveSpecialAlertReq, {
            id: id,
        }).then(({ data }) => {
            if (data?.success === 1) {
                setSpecialAlerts(
                    specialAlerts.map((specialAlert) => {
                        if (id === specialAlert.id) {
                            specialAlert.resolved = true;
                        }
                        return specialAlert;
                    })
                );
            }
        });
    };

    const readSpecialAlerts = () => {
        Axios.get('https://www.smartbiowaste.pt/api/special_action/read.php').then(
            (res) => {
                const alerts = res.data.map((specialAlert) => ({
                    ...specialAlert,
                    seeAllMsg: false,
                }));
                setSpecialAlerts(alerts);
            }
        );
    };

    const alertsValue = {
        alertsX: alerts,
        getAlerts: fetchAlerts,
        seeTheEntireMessage: seeTheEntireMessage,
        seeTheEntireMessageSpecialAlerts: seeTheEntireMessageSpecialAlerts,
        specialAlerts: specialAlerts,
        readSpecialAlerts: readSpecialAlerts,
        resolveAlert: resolveAlert,
        resolveSpecialAlert: resolveSpecialAlert,
    };

    return (
        <AlertsContext.Provider value={alertsValue}>{children}</AlertsContext.Provider>
    );
}

export const useAlertsContext = () => {
    const alertsContext = useContext(AlertsContext);

    if (!alertsContext) {
        throw new Error('useAlertsContext must be used within a AlertsProvider');
    }

    return alertsContext;
};
