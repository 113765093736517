import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import WindowProvider from './context/WindowContext';
import RequestsProvider from './context/RequestsContext';
import LoginProvider from './context/LoginContext';
import AlertsProvider from './context/AlertsContext';
import ContainersProvider from './context/ContainersContext';
import UsersProvider from './context/UsersContext';
import MessagesProvider from './context/MessagesContext';
import PremiosProvider from './context/PremiosContext';
import AccountProvider from './context/AccountContext';
import SuggestionsProvider from './context/SuggestionsContext';
import UserHistoryProvider from './context/UserHistoryContext';
import CollectionProvider from './context/CollectionContext';
import ProtectedRoute from './ProtectedRoute';
import { useWindowContext } from './context/WindowContext';
import Login from './components/login/Login';
import Account from './account/Account';
import TopNav from './components/navs/Top-nav';
import SideBar from './components/navs/Side-nav';
import Content from './components/content/Content';
import Footer from './components/footer/Footer';
import './App.css';

const App = () => {

  // server test basename
  //const basename = "/testes/gestao/";

  // server basename
  const basename = "/";

  return (
    <BrowserRouter basename={basename}>
      <WindowProvider>
        <RequestsProvider>
          <LoginProvider>
            <AlertsProvider>
              <ContainersProvider>
                <UsersProvider>
                  <MessagesProvider>
                    <PremiosProvider>
                      <AccountProvider>
                        <SuggestionsProvider>
                          <UserHistoryProvider>
                            <CollectionProvider>

                    <div className="App">
                      <Switch>
                        <Route exact path="/" component={Login} />
                        <Route /*ProtectedRoute*/ path="/dashboard" component={MainApp} />
                        <Route /*ProtectedRoute*/ exact path="/conta" component={Account} />
                        <Route path="*" component={() => <h1>404 - PAGE NOT FOUND</h1>} />
                      </Switch>
                    </div>
                    
                            </CollectionProvider>
                          </UserHistoryProvider>
                        </SuggestionsProvider>
                      </AccountProvider>
                    </PremiosProvider>
                  </MessagesProvider>
                </UsersProvider>
              </ContainersProvider>
            </AlertsProvider>
          </LoginProvider>
        </RequestsProvider>
      </WindowProvider>
    </BrowserRouter>
  )
};

const MainApp = () => {
 
  // Window Context
  const {
    windowSize,
    useWindowSize
  } = useWindowContext();

  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Open / Close sidebar function
  const handleSidebar = () => {

    setSidebarOpen(!sidebarOpen);
  };

  // Update windowSize state when page resizes
  useWindowSize();

  // Close sidebar when width lower than 600
  useEffect(() => {

    if (windowSize.width <= 600) {

      setSidebarOpen(false);
    };
  }, [windowSize.width]);

  // Change sidebar className on open/close sidebar
  const sidebarColClass = sidebarOpen ? 'sidebar-col-full' : 'sidebar-col-hidden';
  
  // Change content className on open/close sidebar
  const contentColClass = sidebarOpen ? 'content-col-normal' : 'content-col-extended';

  return (
    <div className="MainApp">

      <TopNav />

      <div id="content-row">
        <div className={sidebarColClass} id="sidebar-col">

          <SideBar isOpen={sidebarOpen}
            toggleSidebar={handleSidebar} />

        </div>
        
        <div className={contentColClass} id="content-col">
          <Content />

          <Footer />
        </div>
      </div>

    </div>
  )
};

export default App;