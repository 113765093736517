import React, {useState, useEffect} from 'react';
import { usePremiosContext } from '../../../context/PremiosContext';
import { useRequestsContext } from '../../../context/RequestsContext';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';

const PrizesList = () => {

    const {handleSubmit, register, errors} = useForm();

    //Premios context
    const {
        prizesX,
        getPrizes,
        updatePrize, 
        deletePrize,
        activatePrize
    } = usePremiosContext();

    // request context - to get prefix
    const {
        requestPrefix
    } = useRequestsContext(); 

    // modal to show edit prizes
    const [modal, setModal] = useState(null);
    const toggle = (modalId) => {
        if (modalId) {
            setModal(modalId)
        } else {
            setModal(null)
        }
    }

    //get prizes function
    useEffect(() => {
        getPrizes();
    }, []);

    // render cards on tab Prizes
    const renderPrizesCards = () => { 

        if(prizesX.requestPending) {
            return (
              <div className="table__spinner">
               <span className="spinner-border"></span>
               <span className="spinner__text">A carregar...</span>
              </div>
            )
          }
            
        if (prizesX.length === 0) {

            return (
            <p>Ainda não foram adicionados prémios.</p>
            )
        }

        const cards = prizesX.map(({
            input,
            prizeName,
            description,
            image_path,
            points,
            quantity,
            remaining,
            active
        }) => {
    
        return (
                <div key={input} className="card">
                    <img className="card-img-top prize-image" src={requestPrefix + "/" + image_path}/>
                    
                    <div className="card-body">
                        <div className="card-body__info">
                            <h5 className="card-title">{prizeName}</h5>
                            <p className="card-text">{description}</p>
                        </div>

                        <div>                            
                            <label>Pontos: {points}</label><br></br>
                            <label>Quantidade: {remaining} / {quantity}</label><br></br>
                            <label className="font-weight-bold">Activo: {booleanToString(active)}</label><br></br>
                        </div>
                        
                        <div>
                            <button className="btn btn-sm btn-dark buttons-activate-prize font-weight-bold" onClick={() => handleActivePrize(input, active)}> {renderActButton(active)} </button>
                        </div>

                        <div className="btn-group">
                            <button className="btn btn-sm btn-dark buttons-card font-weight-bold" onClick={() => toggle(input)}><i className="fas fa-pencil-alt"></i> Editar</button>
                            <button className="btn btn-sm btn-dark buttons-card font-weight-bold" onClick={() => deletePrize(input)}><i className="far fa-trash-alt"></i> Deletar</button>
                        </div>
                    </div>
                </div>
        )
    });

    return (
            <div className="card-container">
                {cards}
            </div>
        )
    }

    // active/deactive prize function
    const handleActivePrize = (id, active) => {
        activatePrize(id, !active);
    }

    // function to render text of button 'activate or deactivate'
    const renderActButton = (active) => {
        if (active == 1 || active == true) {
            return <><i className="fas fa-times"></i> Desactivar</>
        } else {
            return <><i className="fas fa-check"></i> Activar</>
        }
    }

    //function to active (boolean) appear as 'yes' or 'no' on the table
    const booleanToString = (active) => {
        if (active == false || active == 0) {
            return "não"
        } else {
            return "sim"
        }
    }

    //edit prizes
    const modalEdit = () => {
            
        if (!modal) {
            return;
        }
        const {input, prizeName, description, points, remaining} = prizesX.find(prize => prize.input === modal)

        return (
                <Modal isOpen={modal} modalTransition={{ timeout: 700 }} toggle={() => toggle()}>
                    <ModalHeader toggle={() => toggle()} id="modalHeader"><i className="fas fa-edit"></i>Editar prémio</ModalHeader>

                        <form onSubmit={handleSubmit(generateCallback(input))} className="edit-form">
                            <ModalBody id="modalBody">
                                    <div className="form-group">
                                        <label className="font-weight-bold">Nome do produto</label>
                                        <input className="form-control" type="text" name="prizeName" ref={register({required: "Necessário preencher campo 'nome do produto'",
                                                                                                    maxLength: { value: 50, message: "Nome do produto demasiado longo. Máximo de 50 caracteres."}})} defaultValue={prizeName}></input>
                                    </div>
                                    {errors.prizeName && <p className="prize-form-errors">{errors.prizeName.message}</p>}

                                    <div className="form-group">
                                        <label className="font-weight-bold">Descrição do produto</label>
                                        <textarea className="form-control" type="text" name="description" ref={register({required: "Necessário preencher campo 'descrição do produto'",
                                                                                         maxLength: { value: 150, message: "Descrição demasiada longa. Máximo de 150 caracteres."}})} defaultValue={description}></textarea>
                                    </div>
                                    {errors.description && <p className="prize-form-errors">{errors.description.message}</p>}

                                    <div className="form-group">
                                        <label className="font-weight-bold">Pontos</label>
                                        <input className="form-control" type="text" name="points" ref={register({required: "Necessário preencher este campo",})} defaultValue={points}></input>
                                    </div>
                                    {errors.points && <p className="prize-form-errors">{errors.points.message}</p>}

                                    <div className="form-group">
                                        <label className="font-weight-bold"> Quantidade disponível</label>
                                        <input className="form-control" type="text" name="quantity" ref={register({required: "Necessário preencher este campo",})} defaultValue={remaining}></input>
                                    </div>
                                    {errors.quantity && <p className="prize-form-errors">{errors.quantity.message}</p>}
                            </ModalBody>

                            <ModalFooter>
                                <div className="form-group">
                                    <button type="submit" className="btn edit-button" title="Guardar"> Guardar <i className="fas fa-check"></i></button>
                                    <button className="btn edit-button" onClick={() => toggle()} title="Cancelar"> Cancelar <i className="fas fa-times"></i></button>
                                </div>
                            </ModalFooter>
                        </form>
                    </Modal>
        )
    }

    // update prize function
    const handleUpdate = (id, {prizeName, description, points, quantity, remaining}) => {
        updatePrize(id, prizeName, description, points, quantity, remaining);
    }

    const actionsEdit = (id, data) => {
        handleUpdate(id, data)
        toggle()
    }

    const generateCallback = (id) => {
        return (...params) => {
            actionsEdit(id, ...params)
        }
    }

    return (
        <div>
            {renderPrizesCards()}
            {modalEdit()}
        </div>
    )
}

export default PrizesList;