import React, {useState} from 'react';
import { Route, Switch } from 'react-router-dom';
import PrizesList from './PrizesList';
import AddNewPrize from './AddNewPrize';
import PrizeStatistics from './PrizeStatistics';
import './Premios.css';

const Premios = () => {

    // button clicked state
    const [selectedTab, setSelectedTab] = useState("listTab");

    //render Tabs
    const renderTab = (isSelected, tabName, text, icon) => {
        if(isSelected) {
            return (
            <button data-toggle="tab" className="active-tab btn btn-dark" onClick={() => selectTab(tabName)}><i className={icon}></i>{text}</button>
            )
        } else {
            return (
                <button data-toggle="tab" className="btn btn-dark" aria-current="page" onClick={() => selectTab(tabName)}><i className={icon}></i>{text}</button>
            )
        }
    }

    const selectTab = (tabName) => {   
        setSelectedTab(tabName);
    }

    const tabPrizes = () => {

        return (
                    <ul className="nav tabs-style">
                    <li className="nav-item style-buttons">
                        {renderTab(selectedTab === "listTab", "listTab", "Prémios", "fas fa-list-ul")}
                    </li>
                    <li className="nav-item style-buttons">
                        {renderTab(selectedTab === "addNewTab", "addNewTab", "Adicionar novo prémio", "fas fa-trophy")}
                    </li>
                    <li className="nav-item style-buttons">
                        {renderTab(selectedTab === "statsTab", "statsTab", "Estatística de prémios escolhidos", "fas fa-chart-bar")}
                    </li>
                    </ul>            
        )           
    }                

    const renderContent = () => {
        switch(selectedTab) {
            case "listTab":
                return <PrizesList />;
            case "addNewTab":
                return <AddNewPrize setSelectedTab={setSelectedTab} />;
            case "statsTab":
                return <PrizeStatistics />
        }

    }

    return (
        <div className="premios">
            
            {tabPrizes()}
            {renderContent()}

        </div>
    );
}

export default Premios;