import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLoginContext } from '../../context/LoginContext';
import { useRequestsContext } from '../../context/RequestsContext';
import './Nav-dropdown.css';

const NavDropdown = (props) => {

    const {
        managers,
        getLoggedManager,
        managerData,
        setManagerData,
        setLoggedManager
    } = useLoginContext();

    // request context - to get prefix
   const {
    requestPrefix
  } = useRequestsContext();

    // email to show from the logged manager
    const [loggedEmail, setLoggedEmail] = useState("");

    // logout function
    const handleLogout = () => {

        setManagerData(null);
        setLoggedManager(null);
    }

    // update loggedEmail when managerData is set/changed
    useEffect(() => {

        if (managerData) {

            setLoggedEmail(managerData.email);
        }
    }, [managerData]);

    return (
        <div className="dropdown">

            <button className="dropbtn">
                {managers.find(({id}) => id == getLoggedManager()).manager_firstname} {managers.find(({id}) => id == getLoggedManager()).manager_lastname}
                <img src={requestPrefix + "/" + managers.find(({id}) => id == getLoggedManager()).manager_picture} alt="Avatar" id="profile-pic" />
            </button>

            <div className="dropdown-content">
                <NavLink exact to='/dashboard' id="dropdown-link">
                    <div id="dropdown-icon">
                        <i className='fas fa-home' ></i>
                    </div>
                    <span>Início</span>
                </NavLink>

                <NavLink exact to='/conta' id="dropdown-link" >
                    <div id="dropdown-icon">
                        <i className='far fa-user' ></i>
                    </div>
                    <span>Conta</span>
                </NavLink>

                <NavLink exact to='/' id="dropdown-link" onClick={handleLogout}>
                    <div id="dropdown-icon">
                        <i className='fas fa-sign-out-alt' ></i>
                    </div>
                    <span>Sair</span>
                </NavLink>
            </div>

        </div>
    )
}

export default NavDropdown;