import React from 'react';
import './HistoryMenu.css';

const HistoryMenu = ({ user, getUserHistory, windowSize, handleClick, searchInitialDate, setSearchInitialDate, searchFinalDate, setSearchFinalDate }) => {
    
    const createDate = (date) => {
        if(!date) {
            return null;
        }
        return new Date(date);
    }

    const submit = () => {
        const userId = user.id;
        const initialDate = document.getElementById("initialDateHistoryGraphic").value;
        const finalDate = document.getElementById("finalDateHistoryGraphic").value;

        getUserHistory(userId, initialDate, finalDate);
        setSearchInitialDate(createDate(initialDate));
        setSearchFinalDate(createDate(finalDate));

        if (windowSize.width <= 850) {
            handleClick();
        }
    }

    return (
            <div className="graphic__controller">
                <div className="graphic__date-input">
                    <label className="font-weight-bold">Selecione um período:</label>
                    <div className="graphic__search-initialDate">
                        <label for="initialDateHistoryGraphic">Desde:</label>
                        <input
                            type="date"
                            name="initialDate"
                            id="initialDateHistoryGraphic"
                            defaultValue={searchInitialDate?.toISOString?.().substr(0, 10) || ""}
                        />
                    </div>

                    <div className="graphic__search-finalDate">
                        <label for="finalDateHistoryGraphic" className="finalDate">Até:</label>
                        <input
                            type="date"
                            name="finalDate"
                            id="finalDateHistoryGraphic"
                            max={searchFinalDate?.toISOString?.().substr(0, 10)}
                            defaultValue={searchFinalDate?.toISOString?.().substr(0, 10) || ''}
                        />
                    </div>
                </div>

                <div>
                    <button className="btn btn-dark btn-sm graphic__search-button" onClick={submit}>Pesquisar</button>
                </div>
            </div>
    )
}

export default HistoryMenu;