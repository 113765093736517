import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useMessagesContext } from '../../../context/MessagesContext';
import { useUsersContext } from '../../../context/UsersContext';
import { useRequestsContext } from '../../../context/RequestsContext';
import TablePagination from '../../Pagination/TablePagination';
import './Mensagens.css';

const Mensagens = () => {

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 5;

  // show "write new message button" state
  const [newMsgClicked, setNewMsgClicked] = useState(false);

  // modal to show attached image in table sent messages
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  //filePath state
  const [imagePath, setImagePath] = useState(null);

  // hook form functions
  const { register, handleSubmit, errors } = useForm();

  // messages context
  const {
    messagesX,
    getMessages,
    insertMessage,
    seeTheEntireMessage,
    deleteMessage
  } = useMessagesContext();

  // user context - to get users info to send the messages
  const {
    usersX,
    getUsers
  } = useUsersContext();

  // request context - to get prefix
  const {
    requestPrefix
  } = useRequestsContext();

  //get messages function
  useEffect(() => {

    getMessages();
  }, [messagesX.length]);

   // get users function
   useEffect(() => {

    getUsers();
  }, [usersX.length]);

  // show "write new message" button
  const handleNewMessage = () => {

    setNewMsgClicked(!newMsgClicked);
  }

  // submit new message (send new message)
  const onSubmit = (data, event) => {
    insertMessage(event, data.user_id, data.title, data.message, data.file);
    handleNewMessage();
  };

  // arrow up or down in "write new msg" button
  const renderCaretNewMsg = () => {
    return (
      <>
        {!newMsgClicked && <i className="fas fa-caret-down"></i>}
        {newMsgClicked && <i className="fas fa-caret-up"></i>}
      </>
    );
  }

  // function to fill select options in form automatically
   const generateSelectOptions = () => {
     
    return usersX.map((
    {
      id,
      first_name,
      last_name,
    }) => {

    return (
      <option value={id}>{`${first_name} ${last_name} - ${id}`}</option>
    )
  });
}

  // Form for send new message
  const sendingMessage = () => {

    // check if "send new message" button has been clicked
    if (!newMsgClicked) {

      return (null);
    }

    return (
      <form className="mb-3 message-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-3">
          <label className="font-weight-bold">Destinatário(s)</label>
          <select name="user_id" className="form-control" ref={register({ required: "Selecione o(s) destinatário(s)", })}>
            <option defaultValue="selected" disabled value="">Escolhe um utilizador</option>
            <option value="0">Todos os utilizadores registados</option>
                                {generateSelectOptions()}
          </select>
          {errors.user_id && <p className="message-form-errors">{errors.user_id.message}</p>}
        </div>

        <div className="mb-3">
          <label className="font-weight-bold">Título da mensagem</label>
          <input name="title" type="text" className="form-control" ref={register({ required: "Título necessário", 
                                                                  maxLength: { value: 100, message: "Título demasiado longo. Máximo de 100 caracteres."}})}></input>
          {errors.title && <p className="message-form-errors">{errors.title.message}</p>}
        </div>

        <div className="mb-3">
          <label className="font-weight-bold">Mensagem a enviar</label>
          <textarea name="message" type="text" className="form-control box-send-message" ref={register({ required: "O campo 'mensagem a enviar' não pode ficar em branco", 
                                                                                       maxLength: { value: 1000, message: "Mensagem demasiada longa. Máximo de 1000 caracteres."}})}></textarea>
          {errors.message && <p className="message-form-errors">{errors.message.message}</p>}
        </div>

        <div className="mb-3">
          <label className="font-weight-bold">Anexar um ficheiro</label>
          <input ref={register} className="form-control" type="file" name="file"></input>
        </div>
        
        <div className="mb-3">
          <button className="font-weight-bold send-message-button" title="Enviar mensagem">Enviar mensagem <i className="fas fa-share-square fa-fw"></i></button>
        </div>

      </form>
    );
  } 

  //render user identification
  const usernameField = (allUsers, first_name, last_name) => {
      if (allUsers) {
        return (
          <span>Todos os utilizadores</span>
        )
      } 
      return (
        <span>{first_name + " " + last_name}</span>
      )
  }

  //render attachment (image file) when click button
  const modalImage = () => {

    return (
      <div>
      <Modal isOpen={modal} modalTransition={{ timeout: 700 }} toggle={toggle} className="modalTest">
        <ModalHeader toggle={toggle} id="modalHeader">Imagem anexada</ModalHeader>
            <ModalBody id="modalBody">
              <img id="uploaded-img" src={requestPrefix + "/" + imagePath} width="300" height="300" />
            </ModalBody>
      </Modal>
      </div>
    )
  }

  const toggleImg = (filePath) => {
      setImagePath(filePath);
      toggle();
  }

  const paginate = (messages) => {
    const page = currentPage;
      return [...messages].splice(page * pageSize, pageSize);
  }

  // render sent messages
  const messagesList = () => {
  
  return paginate(messagesX).map(({
    input,
    allUsers,
    first_name,
    last_name,
    title,
    message,
    datetime,
    file_path,
    seeTheWholeMessage,
  }) => {

    return seeTheWholeMessage ? 
    (
      <>
      <tr key={input} className="sent-messages-style">
        <td data-label="Data de envio">{datetime}</td>
        <td data-label="Destinatário">{usernameField(allUsers, first_name, last_name)}</td>
        <td data-label="Título da mensagem">{title}</td>
        <td className="empty-row-msg"></td>
        <td data-label="Ações">
          <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessage(input)}}><i className="fas fa-eye"></i></button>
          <button type="button" className="btn" title="ver anexo" onClick={() => toggleImg(file_path)} disabled={file_path == "null"}><i className="fas fa-image"></i></button>
          <button type="button" className="btn" title="deletar" onClick={() => {deleteMessage(input)}}><i className="fas fa-trash-alt"></i></button>
        </td>
      </tr>
      <tr>
        <td className="colspan-style" colSpan="5" data-label="Mensagem enviada">{message}</td>
      </tr>
      </>
    ) : (
      <tr key={input} className="messages-table-row">
        <td data-label="Data de envio">{datetime}</td>
        <td data-label="Destinatário">{usernameField(allUsers, first_name, last_name)}</td>
        <td data-label="Título da mensagem">{title}</td>
        <td className="content hideContent">{message}</td>
        <td data-label="Ações">
          <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessage(input)}}><i className="fas fa-eye"></i></button>
          <button type="button" className="btn" title="ver anexo" onClick={() => toggleImg(file_path)} disabled={file_path == "null"}><i className="fas fa-image"></i></button>
          <button type="button" className="btn" title="deletar" onClick={() => {deleteMessage(input)}}><i className="fas fa-trash-alt"></i></button>
        </td>
      </tr>
    )
  });
}

  // render sent messages table 
  const sentMessagesTable = () => {

    if(messagesX.requestPending) {
      return (
        <div className="table__spinner">
         <span className="spinner-border"></span>
         <span className="spinner__text">A carregar...</span>
        </div>
      )
    }

    if (messagesX.length === 0) {

      return (
        <p>Não há mensagens enviadas.</p>
      )
    }

    return (
      <Table className="sent-messages-table">
        <thead className="messages-table-head">
          <tr>
            <th scope="col">Data de envio</th>
            <th scope="col">Destinatário</th>
            <th scope="col">Título da mensagem</th>
            <th scope="col">Mensagem enviada</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody className="sent-messages-table-body">
          {messagesList()}
        </tbody>
      </Table>
    )
  }

  const renderPagination = () => {
    if (messagesX.requestPending) {
      return;
    } if (messagesX.length <= pageSize) {
      return;
    }
    return (<TablePagination pageSize={pageSize} numRows={messagesX.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
  }

  return (
    <div className="messages">
      <button className="message-writenewmsg-button" onClick={handleNewMessage}><i className="far fa-envelope"></i>Escrever nova mensagem {renderCaretNewMsg()}</button>

      {sendingMessage()}

      <label className="sent-messages-label"><i className="fas fa-envelope-open-text fa-fw"></i>Mensagens Enviadas</label>

      {sentMessagesTable()}

      {renderPagination()}

      {modalImage()}

    </div>
  );
}

export default Mensagens;