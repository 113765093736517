import React, { createContext, useContext } from 'react';

const RequestsContext = createContext();

export default function RequestsProvider({ children }) {
    //Local request prefix
    //    const requestPrefix = "http://localhost:3009";

    //Server test request prefix
    //const requestPrefix = "https://www.smartbiowaste.pt/testes/";

    //Server request prefix
    const requestPrefix = 'https://www.smartbiowaste.pt/php-react';

    const requestsValue = {
        requestPrefix: requestPrefix,
    };

    return (
        <RequestsContext.Provider value={requestsValue}>
            {children}
        </RequestsContext.Provider>
    );
}

export const useRequestsContext = () => {
    const requestsContext = useContext(RequestsContext);

    if (!requestsContext) {
        throw new Error('useRequestsContext must be used within a RequestsProvider');
    }

    return requestsContext;
};
