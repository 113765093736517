import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import Axios from 'axios';

const UsersContext = createContext();

export default function UsersProvider({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    const fetchRequest = requestPrefix + "/management-users/read-users.php";

    const [users, setUsers] = useState({requestPending:true});

    // FETCH USERS FROM THE DATABASE
    const fetchUsers = () => {

        Axios.get(fetchRequest)
            .then(({ data }) => {

                if (data.success === 1) {

                    setUsers(data.users);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    const usersValue = {
        usersX: users,
        getUsers: fetchUsers,
    }

    return (
        <UsersContext.Provider value={usersValue}>
            {children}
        </UsersContext.Provider>
    )
}

export const useUsersContext = () => {

    const usersContext = useContext(UsersContext);

    if (!usersContext) {

        throw new Error("useUsersContext must be used within a UsersProvider");
    }

    return usersContext;
}