import React, { createContext, useState, useEffect, useContext } from 'react';

const WindowContext = createContext();

export default function WindowProvider({ children }) {

  // width and height state
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  // windowSize update function
  const useWindowSize = () => {

    useEffect(() => {

      const handleResize = () => {

        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      };

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, [windowSize.width, windowSize.height]);
  };

  const windowValue = {
    windowSize: windowSize,
    useWindowSize: useWindowSize
  }

  return (
    <WindowContext.Provider value={windowValue}>
      {children}
    </WindowContext.Provider>
  )
}

export const useWindowContext = () => {

  const windowContext = useContext(WindowContext);

  if (!windowContext) {

    throw new Error("useWindowContext must be used within a WindowProvider");
  }

  return windowContext;
}