import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './AddNewOperator.css';

const AddNewOperator = ({addOperator}) => {

    const [addClicked, setAddClicked] = useState(false);

    // hook form functions
    const { register, handleSubmit, errors } = useForm();

    const getDateNow = () => {
        return new Date();
    }

    // show form
    const handleAddClick = () => {
        setAddClicked(!addClicked);
    }

   // arrow up or down in "add new operator button"
   const renderCaret = () => {  
        return (
            <>
            {!addClicked && <i className="fas fa-caret-down"></i>}
            {addClicked && <i className="fas fa-caret-up"></i>} 
            </>
        ); 
    }

    const onSubmit = (data, event) => {
        addOperator(event, data.first_name, data.last_name);
        handleAddClick();
    };

    const addForm = () => {

        // check if add button has been clicked
        if (!addClicked) {
            return (null);
        }

        return (
            <form className="new-operator-form row" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="col-lg-5 col-md-6 col-sm-12">
                        <label className="font-weight-bold">Primeiro nome</label> 
                        <input name="first_name" type="text" className="form-control" ref={register({required: "Necessário preencher o campo 'Primeiro nome'",})}></input>
                    {errors.first_name && <p className="operator-form-errors">{errors.first_name.message}</p>}
                    </div>
                    
                    <div className="col-lg-5 col-md-6 col-sm-12">
                        <label className="font-weight-bold">Último nome</label>
                        <input name="last_name" type="text" className="form-control" ref={register({required: "Necessário preencher o campo 'Último nome'",})}></input>
                    {errors.last_name && <p className="operator-form-errors">{errors.last_name.message}</p>}
                    </div>

                    <div className="col-lg-1 col-md-6 col-sm-12">
                        <button type="submit" className="btn btn-dark" title="Adicionar"><i className="fas fa-plus"></i></button>
                    </div>
            </form>
         );
    }

   return (
    <div className="add-new-operator">
        <button className="new-operator-button" onClick={handleAddClick}><i className="fas fa-user"></i> Adicionar novo operador de recolha {renderCaret()}</button>
        {addForm()}
    </div>
    )
}

export default AddNewOperator;