import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import Historico from './userHistory/Historico';
import Utilizadores from './userList/Utilizadores';

const UserRouter = () => {

    const [user, setUser] = useState(null);
    
    return (
        <Switch>
            <Route exact path="/dashboard/utilizadores" component={() => <Utilizadores setUser={setUser}/>}></Route>
            <Route exact path="/dashboard/utilizadores/historico" component={() => <Historico user={user}/>}></Route>
        </Switch>
    )
}

export default UserRouter;