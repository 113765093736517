import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'reactstrap';
import { useContainersContext } from '../../../context/ContainersContext';
import { useForm } from 'react-hook-form';
import TablePagination from '../../Pagination/TablePagination';
import './Contentores.css';

const Contentores = () => {

    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 5;

    // show add container form state
    const [addClicked, setAddClicked] = useState(false);

    // hook form functions
    const { register, handleSubmit, errors } = useForm();

    // lat Ref
    const latRef = useRef();

     // lon Ref
     const lonRef = useRef();

     // serial_number(sn) Ref
    const snRef = useRef();

    // type Ref
    const typeRef = useRef();

    // containers context
    const {
        containersX,
        getContainers,
        editMode,
        cancelEdit,
        updateContainer,
        deleteContainer,
        insertContainer
    } = useContainersContext();

    // get containers function
    useEffect(() => {

        getContainers();
    }, [containersX.length]);

    // update container function
    const handleUpdate = (id) => {

        updateContainer(id, latRef.current.value, lonRef.current.value, snRef.current.value, typeRef.current.value);
    }

    // coordinates (latitude and longitude validation)
    const coordinatesValidation = (latitude, longitude) => {
    let latRegex = new RegExp("^-?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$");
    let lonRegex = new RegExp ("/^-?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[1-7][0-9])(?:(?:\.[0-9]{1,6})?))$");
    let latValidation = latRegex.test(latitude);
    let lonValidation = lonRegex.test(longitude);
    let error = {};

        if (latValidation === false) {
            return error = "Latitude deve ser preenchida no formato: 00.000000" 
        } else if (lonValidation === false) {
            return error = "Longitude deve ser preenchida no formato: 000.000000"
        }
    }

    // show add container form
    const handleAddClick = () => {

        setAddClicked(!addClicked);
    }

    // submit new container (add container)
    const onSubmit = (data, event) => {

        insertContainer(event, data.lat, data.lon, data.sn, data.container_type);
        handleAddClick();
    };

    // form for add container
    const addForm = () => {

        // check if add button has been clicked
        if (!addClicked) {

            return (null);
        }

        return (
            <form className="container-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <label className="font-weight-bold">Coordenadas GPS em graus decimais</label> 
                            <div className="row">                        
                                <div className="col">
                                    <input name="lat" type="text" className="form-control" placeholder="Latitude" ref={register({required: "Coordenadas GPS necessárias",})}></input>
                                </div>
                                <div className="col">
                                    <input name="lon" type="text" className="form-control" placeholder="Longitude" ref={register({required: "Coordenadas GPS necessárias",})}></input>
                                </div>
                            </div>
                    {errors.lat && <p className="container-form-errors">{errors.lat.message}</p>}
                    {errors.lon && <p className="container-form-errors">{errors.lon.message}</p>}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <label className="font-weight-bold">Número de série</label> 
                            <input name="sn" type="text" className="form-control" ref={register({required: "Número de série necessário",})}></input>
                    {errors.sn && <p className="container-form-errors">{errors.sn.message}</p>}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="font-weight-bold">Tipo de contentor</label>
                            <select id="inputState" name="container_type" className="form-control" ref={register({required: "Selecione o tipo de contentor",})}>
                                <option defaultValue="selected" disabled value="">Escolhe uma opção</option>
                                <option value="0">Proximidade</option>
                                <option value="1">Individual</option>
                            </select>
                    {errors.container_type && <p className="container-form-errors">{errors.container_type.message}</p>}
                    </div>

                    <div className="col-lg-1 col-md-6 col-sm-12 container-form-control">
                        <button type="submit" className="btn btn-dark" title="Adicionar"><i className="fas fa-plus"></i></button>
                    </div>
                </div>
            </form>
         );
    }

    //for the type of container appear as "proximidade" or "individual" on the table (not 0 or 1)
    const typeToString = (container_type) => {
        if (container_type === "0") {
           return "Proximidade"
        } else {
            return  "Individual"
        }
    }

    //render google maps location
    const mapsLocation = (latitude, longitude) => {
        return (
            <a target="_blank" href={"http://www.google.com/maps/place/" + latitude + "," + longitude}>Clica aqui para ver</a>
        )
    }

    const paginate = (containers) => {
        const page = currentPage;
          return [...containers].splice(page * pageSize, pageSize);
    }

    // render containers
    const containersList = () => {
        
        return paginate(containersX).map((
        {
            input,
            datetime,
            latitude,
            longitude,
            serial_number,
            container_type,
            isEditing
        }) => {

        return isEditing ?
        (
            <tr key={input} className="containers-table-row">
                <td scope="row" data-label="ID do Contentor"><input className="form-control" type="text" defaultValue={input} disabled/></td>
                <td data-label="Latitude"><input className="form-control" type="text" name="lat" ref={latRef} defaultValue={latitude} placeholder="Latitude"/></td>
                <td data-label="Longitude"><input className="form-control" type="text" name="lon" ref={lonRef} defaultValue={longitude} placeholder="Longitude"/></td>
                <td></td>
                <td data-label="Número de série"><input className="form-control" type="text" name="sn" ref={snRef} defaultValue={serial_number} /></td>
                 <td data-label="Tipo"><select id="inputState" className="form-control" name="container_type" ref={typeRef} defaultValue={container_type}> 
                        <option selected="selected" disabled value="">Escolhe uma opção</option>
                            <option value="0">Proximidade</option>
                            <option value="1">Individual</option>
                    </select>
                </td>
                <td data-label="Data de registo">{datetime}</td>
                <td className="container-buttons-edit">
                    <button className="btn edit-button" onClick={() => handleUpdate(input)} title="Guardar"><i className="fas fa-check"></i></button>
                    <button className="btn edit-button" onClick={() => cancelEdit(input)} title="Cancelar"><i className="fas fa-times"></i></button>
                </td>
            </tr>
            ) : (
                <tr key={input} className="containers-table-row">
                    <td scope="row" data-label="ID do Contentor">{input}</td>
                    <td data-label="Latitude">{latitude}</td>
                    <td data-label="Longitude">{longitude}</td>
                    <td data-label="Localização no mapa">{mapsLocation(latitude, longitude)}</td>
                    <td data-label="Número de série">{serial_number}</td>
                    <td data-label="Tipo">{typeToString(container_type)}</td>
                    <td data-label="Data de registo">{datetime}</td>
                    <td className="containers-list-buttons" data-label="Editar/Deletar">
                        <button className="btn" onClick={() => editMode(input)} title="Editar"><i className="fas fa-pencil-alt"></i></button>
                        <button className="btn" onClick={() => deleteContainer(input)} title="Deletar"><i className="fas fa-trash-alt"></i></button>
                    </td>
                </tr>
            )
        });
    }

    // arrow up or down in "add new container button"
    const renderCaret = () => {  
        return (
        <>
            {!addClicked && <i className="fas fa-caret-down"></i>}
            {addClicked && <i className="fas fa-caret-up"></i>} 
        </>
        ); 
    }

    // render containers table
    const containersTable = () => {

        if(containersX.requestPending) {
            return (
              <div className="table__spinner">
               <span className="spinner-border"></span>
               <span className="spinner__text">A carregar...</span>
              </div>
            )
          }

        if (containersX.length === 0) {

            return (
                <p>Não existem contentores!</p>
            )
        }

        return (
            <Table className="containers-table">
                <thead className="containers-table-head">
                    <tr>
                        <th scope="col">ID do Contentor</th>
                        <th scope="col">Latitude</th>
                        <th scope="col">Longitude</th>
                        <th scope="col">Localização no mapa</th>
                        <th scope="col">Número de série</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Data de registo</th>
                        <th scope="col">Editar/Deletar</th>
                    </tr>
                </thead>
                <tbody className="containers-table-body">
                    {containersList()}
                </tbody>
            </Table>
        )
    }

    const renderPagination = () => {
        if (containersX.requestPending) {
          return;
        } if (containersX.length <= pageSize) {
          return;
        }
        return (<TablePagination pageSize={pageSize} numRows={containersX.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
      }

    return (
        <div id="containers">
            <button className="container-add-button" onClick={handleAddClick}><i className="fas fa-dumpster"></i> Adicionar novo contentor {renderCaret()}</button>

            {addForm()}
        
            <div className="container__table">
                <label className="registered-containers-table"><i className="fas fa-trash-alt fa-fw"></i> Contentores</label>
                {containersTable()}
                {renderPagination()}
            </div>

        </div>
    );
}

export default Contentores;