import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import { useLoginContext } from './LoginContext';
import Axios from 'axios';

const AccountContext = createContext();

export default function AccountProvider({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    //Login Context
    const {
        managers,
        getLoggedManager,
        setManagersList
    } = useLoginContext();

    const updateRequest = requestPrefix + "/management-managers/update-manager.php";
    const newManagerRequest = requestPrefix + "/management-managers/create-manager.php";
    const deleteRequest = requestPrefix + "/management-managers/delete-manager.php";
    const uploadRequest = requestPrefix + "/files/upload-file.php"

    const uploadImage = (file) => {
        var formData = new FormData();
        formData.append("file", file[0]);
        return Axios.post(uploadRequest, formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
    }

    //Update manager picture
    const updatePicture = (managerId, managerPicture) => {
        uploadImage(managerPicture).then(({ data: uploadedData }) => {
            Axios.post(updateRequest,
                {
                    id: managerId,
                    managerPicture: uploadedData.file,
                })
                .then(({ data }) => {
    
                    if (data.success === 1) {
    
                        let managersArr = managers.map(manager => {
                            if (manager.id === managerId) {
                                manager.manager_picture = uploadedData.file;
                            }
                            
                            return manager;
                        })
    
                        setManagersList(managersArr);
    
                    } else {
    
                        alert(data.msg);
    
                    }
                })   
        })
    }

    //Update manager pass
    const updatePass = (managerId, managerPassword) => {
        Axios.post(updateRequest,
            {
                id: managerId,
                managerPassword: managerPassword,
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    let managersArr = managers.map(manager => {
                        if (manager.id === managerId) {
                            manager.manager_password = managerPassword;
                        }
                        
                        return manager;
                    })

                    setManagersList(managersArr);

                } else {

                    alert(data.msg);

                }
            })   
    }

    const updateName = (managerId, managerFirstName, managerLastName) => {
        Axios.post(updateRequest,
            {
                id: managerId,
                managerFirstName: managerFirstName,
                managerLastName: managerLastName
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    let managersArr = managers.map(manager => {
                        if (manager.id === managerId) {
                            manager.manager_firstname = managerFirstName;
                            manager.manager_lastname = managerLastName;
                        }
                        
                        return manager;
                    })

                    setManagersList(managersArr);

                } else {

                    alert(data.msg);

                }
            })   
    }

    //Super manager: create new accounts
    const createNewAccount = (event, managerEmail, managerPassword, managerFirstName, managerLastName, admin) => {

        event.preventDefault();
        event.persist();

        Axios.post(newManagerRequest,
            {
                managerEmail: managerEmail,
                managerPassword: managerPassword,
                managerFirstName: managerFirstName,
                managerLastName: managerLastName,
                admin: admin
            })
            .then(({ data }) => {
    
                if (data.success === 1) {

                    let managersArr = [...managers];
                    managersArr.push({
                        id: data.id,
                        manager_email: managerEmail,
                        manager_password: managerPassword,
                        manager_firstname: managerFirstName,
                        manager_lastname: managerLastName,
                        admin: admin

                    });

                    setManagersList(managersArr);

                    event.target.reset();

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    //Super manager: delete accounts
    const deleteAccount = (id) => {

        let managersArr = managers.filter(manager => manager.id !== id);

        Axios.post(deleteRequest,
            {
                id: id
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    setManagersList(managersArr);

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    const accountValue = {
    updatePicture, updatePass, updateName, createNewAccount, deleteAccount
    }

    return (
        <AccountContext.Provider value={accountValue}>
            {children}
        </AccountContext.Provider>
    )
}

export const useAccountContext = () => {

    const accountContext = useContext(AccountContext);

    if (!accountContext) {

        throw new Error("useAccountContext must be used within a AccountProvider");
    }

    return accountContext;
}