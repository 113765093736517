import React, {useState} from 'react';
import { Table } from 'reactstrap';
import TablePagination from '../../Pagination/TablePagination';

const PrizeAlerts = ({prizeAlertsX, sendPrize}) => {

    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 5;

    // show prize alerts button
    const [buttonClicked, setButtonClicked] = useState(false);

    // open/close button
    const handleButtonClick = () => {
        setButtonClicked(!buttonClicked);
    }

    // arrow up or down in "prize alerts button"
    const renderCaret = () => {  
        return (
        <>
            {!buttonClicked && <i className="fas fa-caret-down"></i>}
            {buttonClicked && <i className="fas fa-caret-up"></i>} 
        </>
        ); 
    }

      // function to show yes or no instead of 1 or 0
      const sentToString = (sent) => {
        if (sent == 0) {
           return "não"
        } else {
            return  "sim"
        }
    }

    // send prize function
    const handleSendPrize = (id, sent) => {
        if (sent == 0) {
            sendPrize(id, true);
        } else {
            sendPrize(id, false);
        }
    }

    const paginate = (prizeAlerts) => {
        const page = currentPage;
          return [...prizeAlerts].splice(page * pageSize, pageSize);
    }

    // prize alerts list
    const prizeAlertsList = () => {
        
        return paginate(prizeAlertsX).map((
        {
            id,
            datetime,
            first_name,
            last_name,
            prize_name,
            prize_remaining,
            sent
        }) => {
       
            return (
                <tr key={id} className="prize-alerts-table-row">
                    <td data-label="Data" scope="row">{datetime}</td>
                    <td data-label="Utilizador">{first_name} {last_name}</td>
                    <td data-label="Prémio">{prize_name}</td>
                    <td data-label="Itens restantes">{prize_remaining}</td>
                    <td data-label="Enviado">{sentToString(sent)}</td>
                    <td data-label="Enviar prémio">
                        <button className="btn send-button" title="Enviar" disabled={sent == true} onClick={() => handleSendPrize(id, sent)}><i className="fas fa-share"></i></button>
                    </td>
                </tr>
            )
    });
}

    // render table
    const renderPrizeAlertsTable = () => {

        if(prizeAlertsX.requestPending) {
            return (
              <div className="table__spinner">
               <span className="spinner-border"></span>
               <span className="spinner__text">A carregar...</span>
              </div>
            )
        }

        // check if button has been clicked
        if (!buttonClicked) {

            return (null);
        }
        // check if there are any prize alert
        else if (prizeAlertsX.length === 0) {
            return (
                <p>Não foram escolhidos prémios.</p>
            )
        }
        return (
            <Table className="prize-alerts-table">
                <thead className="prize-alerts-table-head">
                    <tr>
                        <th scope="col">Data</th>
                        <th scope="col">Utilizador</th>
                        <th scope="col">Prémio</th>
                        <th scope="col">Itens restantes</th>
                        <th scope="col">Enviado</th>
                        <th scope="col">Enviar prémio</th>
                    </tr>
                </thead>
                <tbody className="prize-alerts-table-body">
                    {prizeAlertsList()}
                </tbody>
            </Table>
        )
    }

    const renderPagination = () => {
        if (!buttonClicked) {
            return null;
        }
        if (prizeAlertsX.requestPending) {
          return;
        } if (prizeAlertsX.length <= pageSize) {
          return;
        }
        return (<TablePagination pageSize={pageSize} numRows={prizeAlertsX.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
      }

    return (
        <div className="prizeAlerts">
            <button onClick={handleButtonClick} className="prize-alerts-button">Prémios escolhidos pelos utilizadores {renderCaret()}</button>
            {renderPrizeAlertsTable()}
            {renderPagination()}
        </div>
    )
}

export default PrizeAlerts;