import React, {useEffect} from 'react';
import { usePremiosContext } from '../../../context/PremiosContext';
import PrizeAlerts from './PrizeAlerts';
import PrizeStatisticsDashboard from './PrizeStatisticsDashboard';

const PrizeStatistics = () => {

    const {
        prizeAlertsX,
        prizesX,
        fetchPrizeAlerts,
        getPrizes,
        sendPrize
    } = usePremiosContext();

    useEffect (() => {
        fetchPrizeAlerts();
        getPrizes();
    }, []);


    return (
        <div className="prizes__statistics">
            <PrizeStatisticsDashboard prizesList={prizesX} alertsList={prizeAlertsX}/>
            <PrizeAlerts prizeAlertsX={prizeAlertsX} sendPrize={sendPrize}/>
        </div>
    )
}

export default PrizeStatistics;