import React, {useState} from 'react';
import {BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar} from 'recharts';

var getDaysInMonth = function(month,year) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
   return new Date(year, month, 0).getDate();
  // Here January is 0 based
  // return new Date(year, month+1, 0).getDate();
  };

const PrizeStatisticsDashboard = ({prizesList, alertsList}) => {


    const [searchDate, setSearchDate] = useState({
        year: new Date(Date.now()).getFullYear(),
        month: new Date(Date.now()).getMonth() + 1
    });
    
    const renderData = () => {
        let data = []
        if (alertsList?.requestPending || prizesList?.requestPending) {
            return data;
        }
        let daysInMonth = getDaysInMonth(searchDate.month, searchDate.year);
        for(let day = 1; day <= daysInMonth; day++) {
            data.push({
                date: day,
            })
        }
        prizesList.forEach(({prizeName}) => {
            data = data.map(dataDay => {
                dataDay[prizeName] = alertsList.filter(({prize_name, datetime}) => {
                    let prizeAlertDate = new Date(datetime);
                    return prize_name === prizeName 
                    && prizeAlertDate.getDate() === dataDay.date
                    && prizeAlertDate.getMonth() + 1 == searchDate.month
                    && prizeAlertDate.getFullYear() == searchDate.year}).length;
                return dataDay;
            })                
        });
        return data;
    }

    const [data, setData] = useState(renderData());


    const renderGraph = (graphData) => {
        return (
            <BarChart width={1080} height={250} data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date"/>
                <YAxis />
                <Tooltip />
                <Legend />
                {renderBars()}
            </BarChart>
        )
    }

    const renderBars = () => {
        if (prizesList?.requestPending) {
            return [];
        }
        return prizesList.map(({prizeName}) => (<Bar dataKey={prizeName} stackId="a" fill={'#' + Math.floor(Math.random()*16777215).toString(16)} />));
    }

    return (
        <>
        <p className="dashboard__title">Nº de prémios/dia</p>
            <div className="dashboard">
                {renderGraph(data)}
                <div className="dashboard__controller">
                    <p>Selecione um período:</p>
                    <div className="dashboard__date-input">
                        <select defaultValue={searchDate.month} onChange={(ev) => setSearchDate({...searchDate, month: ev.target.value})}>
                            <option value="1">Janeiro</option>
                            <option value="2">Fevereiro</option>
                            <option value="3">Março</option>
                            <option value="4">Abril</option>
                            <option value="5">Maio</option>
                            <option value="6">Junho</option>
                            <option value="7">Julho</option>
                            <option value="8">Agosto</option>
                            <option value="9">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                        <input type="number" defaultValue={searchDate.year} onChange={(ev) => setSearchDate({...searchDate, year: ev.target.value})}></input>
                    </div>
                    <button className="dashboard__button--date-input btn btn-dark btn-sm" onClick={() => setData(renderData())}>Pesquisar</button>
                </div>
            </div>
        </>
    )
}

export default PrizeStatisticsDashboard