import React, { useState } from 'react';
import { Nav, NavItem, NavLink, ToastHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import './AlertsFilter.css';

const AlertsFilter = ({history, tabs, activeTab, toggle, setDate }) => {
   
    const [tempDate, setTempDate] = useState(null);

    const submit = () => {
        setDate(new Date(tempDate));
    }

    // render tabs
    const tabsList = tabs.map(tab => {

        return (
            <NavItem className="alert-tab-item" key={tab.id}>
                <NavLink
                    className={classnames({ active: activeTab === tab.name })}
                    onClick={() => { toggle(tab.name); }}
                    id="alert-tab-link" >
                    <ToastHeader icon={tab.iconColor} className="alert-tab-header">{tab.name}</ToastHeader>
                </NavLink>
            </NavItem>
        )
    });

    return (
            <div className="alerts-filter">
                <div className="alerts-filter__filters">
                    <span>Filtros</span>
                    <Nav tabs className="alerts-filter-tabs">
                        {tabsList}
                    </Nav>
                </div>
                <div className="alerts-filter__links">
                  <span>Links</span>
                  <Nav tabs className="alerts-filter-tabs">
                        <NavItem className="alert-tab-item">
                            <NavLink id="alert-tab-link" onClick={() => history.push("/dashboard/premios/estatisticas") }>
                                <ToastHeader icon={"info"} className="alert-tab-header">Prémios</ToastHeader>
                            </NavLink>
                        </NavItem>
                        <NavItem className="alert-tab-item">
                            <NavLink id="alert-tab-link" onClick={() => history.push("/dashboard/sugestoes")}>
                                <ToastHeader icon={"primary"} className="alert-tab-header">Sugestões</ToastHeader>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="alerts-filter__search">
                <label for="dataAlert">Mostrar alertas desde:</label>
                        <input className="form-control"
                            type="date"
                            name="date"
                            id="dataAlert"
                            placeholder="date placeholder"
                            onChange={(ev) => setTempDate(ev.target.value)}
                        />
                    
                    <button className="btn btn-dark btn-sm search-button" onClick={submit}>Pesquisar</button>
                </div>
            </div>
    );
}

export default withRouter(AlertsFilter);