import React from 'react';
import NavDropdown from '../nav-dropdown/Nav-dropdown';
import { withRouter } from 'react-router-dom';
import NavLogo from '../../images/NavBarLogo.png';
import ImageMap from 'image-map';
import './Top-nav.css';

const TopNav = (props) => {

  // Call imageMap to use on topnav icon
  ImageMap('img[useMap]');

  return (
    <div id="top-nav">

      <img src={NavLogo} id="nav-logo" alt="navBarLogo" useMap="#nav-logo-map" />

      <map name="nav-logo-map">
        <area shape="rect"
          coords="500,129,110,0"
          alt="smartbiowaste"
          onClick={() => {props.history.push("/dashboard")}}
          target="_blank"
          id="nav-logomap-area" />
      </map>

      <div id="nav-dropdown">
        <NavDropdown />
      </div>

    </div>
  );
}

export default withRouter(TopNav);