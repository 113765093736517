import React from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts';
import './Historico.css';

const UserHistoryGraphic = ({ userHistoryX, searchInitialDate, searchFinalDate }) => {

    const timeRange = () => {
        if (!searchFinalDate || !searchInitialDate) {
            return 0
        }

        const timeInMs = searchFinalDate.getTime() - searchInitialDate.getTime()
        return timeInMs / 86_400_000
    }

    const renderGraphic = (graphData) => {
        return (
            <ResponsiveContainer width="100%" height={250}>
            <BarChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="weight" name="Peso em Kg" fill="#343a40" />
            </BarChart>
            </ResponsiveContainer>
        )
    }

    const renderData = () => {
        let data = [];
        if (userHistoryX.requestPending) {
            return data;
        }

        let tempDate = new Date(searchInitialDate)

        const eventArr = [...userHistoryX];
        let currentEvent = eventArr.pop();
        
        for (let i = 0; i <= timeRange(); i++) {
            
            let weight = 0;
            
            while (compareDate(currentEvent, tempDate)) {
                weight += parseFloat(currentEvent.weight);
                currentEvent = eventArr.pop();
            }
            
            data.push({
                date: tempDate.getDate(),
                weight
            })
            
            tempDate.setDate(tempDate.getDate() + 1)
        }
        return data;
    }
    
    const compareDate = (currentEvent, currentDate) => {
        if(!currentEvent?.datatime) {
            return false
        }
        const event = new Date(currentEvent.datatime)
        return event.getFullYear() === currentDate.getFullYear() &&
        event.getMonth() === currentDate.getMonth() &&
        event.getDate() === currentDate.getDate(); 
        
    }

    return (
        <>
            {renderGraphic(renderData())}
        </>
    )
}

export default UserHistoryGraphic;