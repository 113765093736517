import React, { useState } from 'react';
import { useLoginContext } from '../../context/LoginContext';
import './Side-nav.css';
import './Hamburger.css';

const SideBarAcc = ({ isOpen, toggleSidebar, toggleSelected, selectedButton }) => {

// Login Context
  const {
    managers,
    getLoggedManager
  } = useLoginContext();

    // Hamburguer button state
    const [toggleButton, setToggleButton] = useState(
        { name: 'GESTOR', id: 1, icon: 'fa fa-id-card' }
    );

    const getLinks = () => {
        let linkArr = [{ name: 'PERFIL', id: 1, icon: 'fas fa-user', disabled: false }];

        const loggedManagerInfo = managers.find((manager) => getLoggedManager() == manager.id);
        
        if (loggedManagerInfo.admin == 1 || loggedManagerInfo.admin == true) {
            linkArr.push({ name: 'CRIAR NOVA CONTA', id: 2, icon: 'fas fa-user-friends', disabled: false}, {name: 'OPERADORES', id: 3, icon: 'fas fa-hard-hat', disabled: false});
        }
        return linkArr;
    }

   // Links State
    const [sideButtons, setSideButtons] = useState(getLinks());

    // Change hamburguer className on open/close sidebar
    const hamburgerClass = isOpen ? 'hamburger hamburger--arrow is-active' : 'hamburger hamburger--arrow';
    
    // Change sidebar className on open/close sidebar
    const sidebarClass = isOpen ? 'sidebar-full' : 'sidebar-hidden';
    
    // Change iconsize on open/close sidebar
    const iconSize = isOpen ? ' fa-lg' : ' fa-2x';

    // render dashboard and hamburguer/arrow button
    const dashboardItem = () => {

        return (
            <div id="dashboard-item">
                <span id="button-box">
                    <button className={hamburgerClass} id="hamburger-button"
                        type="button" onClick={toggleSidebar}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </span>
                <span id="dashboard-icon">
                    <i className={toggleButton.icon + " fa-lg"}></i>
                </span>
                <span id="dashboard-text">
                    {toggleButton.name}
                </span>
            </div>
        )
    };

    // render nav links
    const buttonsList = sideButtons.map(button => {
        let buttonClass = sidebarClass;
            buttonClass += button.id === selectedButton ? " active-link" : "";

        return (
           
            <a className={buttonClass + " account-link"} id="side-link" onClick={() => {toggleSelected(button.id)}}>
                <i className={button.icon + iconSize} id="side-icon"></i>
                <span className="sidenav__text">{button.name}</span>
            </a>
        )
    });

    return (
        <div className={sidebarClass} id="sidebar" >
            {dashboardItem()}
            {buttonsList}
        </div>
    );
}

export default SideBarAcc;