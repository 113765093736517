import React , {useState, useEffect} from 'react';
import { useWindowContext } from '../context/WindowContext';
import { useRequestsContext } from '../context/RequestsContext';
import { useLoginContext } from '../context/LoginContext';
import { useAccountContext } from '../context/AccountContext';
import SideNavAccount from '../components/navs/Side-nav-account';
import TopNav from '../components/navs/Top-nav';
import Footer from '../components/footer/Footer';
import Profile from './Profile';
import CreateNewAccount from './CreateNewAccount';
import Operators from './operators/Operators';
import './Account.css';

const Account = () => {

const [sidebarOpen, setSidebarOpen] = useState(true);
const [selectedButton, setSelectedButton] = useState(1);

// Window Context
const {
    windowSize,
    useWindowSize
  } = useWindowContext();

// request context
  const {
    requestPrefix
  } = useRequestsContext();

  // Login Context
  const {
    managers,
    getLoggedManager,
    getManagers
  } = useLoginContext();

  // Account Context
  const {
    updatePicture,
    updatePass,
    updateName
  } = useAccountContext();

// Open / Close sidebar function
const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Update windowSize state when page resizes
  useWindowSize();

  // Close sidebar when width lower than 600
  useEffect(() => {
    if (windowSize.width <= 600) {
      setSidebarOpen(false);
    };
  }, [windowSize.width]);

  // Change sidebar className on open/close sidebar
  const sidebarColClass = sidebarOpen ? 'sidebar-col-full' : 'sidebar-col-hidden';
 
  const changeState = (state) => {
      setSelectedButton(state)
  }
  
    //render content
    const renderContent = () => {   
        switch(selectedButton) {
          case 1:
            return <Profile managers={managers} requestPrefix={requestPrefix} getLoggedManager={getLoggedManager} updatePass={updatePass} updatePicture={updatePicture} updateName={updateName}/>
          case 2:
            return <CreateNewAccount managers={managers} getManagers={getManagers}/>
          case 3:
            return <Operators />
        }
    }
    
    return (
        <div id="account">
          <TopNav />
          
          <div id="content-row">
            <div className={sidebarColClass} id="sidebar-col">
              <SideNavAccount isOpen={sidebarOpen}
              toggleSidebar={handleSidebar} toggleSelected={changeState} selectedButton={selectedButton}/>
            </div>

            <div className="content-container">
              <div className="content">
                {renderContent()}
              </div>

              <Footer />
            </div>
          </div>
        </div>
    );
};

export default Account;