import React, { useEffect, useState } from 'react';
import { useCollectionContext } from '../../../context/CollectionContext';
import { Table } from 'reactstrap';
import TablePagination from '../../Pagination/TablePagination';
import './Recolhas.css';

const Recolhas = () => {

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 9;

  const {
      collectionsX,
      getCollections,
      operatorsX,
      getOperators
  } = useCollectionContext();

  useEffect(() => {
    getCollections();
    getOperators();
  }, []);

  //function to show operator´s name instead of operator number
  const operatorName = (keycode) => {
    if (operatorsX.requestPending) {
      return;
    }
    return operatorsX.find(({keycode: code}) => code === keycode)?.operator;
  }

  const collectionsList  = () => {

    return paginate(collectionsX).map((
      {
        input,
        datatime,
        keycode,
        container,
        weight,
      }) => {
              
          return (
            <tr key={input}>
              <td scope="row" data-label="ID do contentor">{container}</td>
              <td data-label="Operador">{operatorName(keycode)}</td>
              <td data-label="Quantidade recolhida (kg)">{weight ?? '--'}</td>
              <td data-label="Data">{datatime}</td>
            </tr>
          )
        });
  }

    const paginate = (collections) => {
        const page = currentPage;
            return [...collections].splice(page * pageSize, pageSize);
    }

    // render table
    const ccollectionsTable = () => {

    if(collectionsX.requestPending) {
      return (
        <div className="table__spinner">
         <span className="spinner-border"></span>
         <span className="spinner__text">A carregar...</span>
        </div>
      )
    } 

    if (collectionsX.length === 0) {

      return (
        <p>Ainda não foram feitas recolhas.</p>
      )
    }

  return(
    <Table>
      <thead className="collections-table-head">
        <tr>
          <th scope="col">ID do contentor</th>
          <th scope="col">Operador</th>
          <th scope="col">Quantidade recolhida (kg) </th>
          <th scope="col">Data</th>
        </tr>
      </thead>

      <tbody className="collections-table-body">
          {collectionsList()}
      </tbody>
    </Table>
  )
}

    const renderPagination = () => {
        if (collectionsX.requestPending) {
        return; 
        } if (collectionsX.length <= pageSize) {
        return;
        }
        return (<TablePagination pageSize={pageSize} numRows={collectionsX.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
    }

    return (
        <div className="collections-section">
             <label className="table-title"><i className="fas fa-recycle"></i>Últimas Recolhas</label>
             {ccollectionsTable()}
             {renderPagination()}
        </div>
    )
}

export default Recolhas;