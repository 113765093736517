import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import Axios from 'axios';

const LoginContext = createContext();

export default function LoginProvider({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    const fetchRequest = requestPrefix + "/management-managers/read-managers.php";
    
    // Input data from login
    const [managerData, setManagerData] = useState(null);

    // Managers registered in the database
    const [managers, setManagers] = useState([]);

    // Manager that is currently logged in (id)
    const [loggedInManager, setLoggedInManager] = useState(null);

    // persist logged manager
    const getLoggedManager = () => {
        let manager = loggedInManager;
        if (!manager) {
            manager = sessionStorage.getItem("manager");
            if (!manager) {
                //send to login page
            }
            setLoggedInManager(manager);
        }
        return manager;
    }

    const setLoggedManager = (manager) => {
        if (manager === null) {
            sessionStorage.clear();
            return;
        }
        setLoggedInManager(manager.id);
        sessionStorage.setItem("manager", manager.id);
    }

    const getManagersList = () => {
        let managersList = managers;
        if (managersList.length < 1) {
            managersList = JSON.parse(sessionStorage.getItem("managerList"));
            if(managersList === null) {
                return;
            }
            setManagers(managersList);
        }
        return managersList;
    }

    const setManagersList = (managersList) => {
        if(managersList === null) {
            sessionStorage.clear();
        }
        setManagers(managersList);
        sessionStorage.setItem("managerList", JSON.stringify(managersList));
    }

    // FETCH MANAGERS FROM THE DATABASE
    const fetchManagers = () => {

        Axios.get(fetchRequest)
            .then(({ data }) => {

                if (data.success === 1) {

                    setManagersList(data.managers);
    
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    const managersValue = {
        managers: getManagersList(),
        managerData: managerData,
        getManagers: fetchManagers,
        getLoggedManager: getLoggedManager,
        setLoggedManager: setLoggedManager,
        setManagerData: setManagerData,
        setManagersList: setManagersList
    }

    return (
        <LoginContext.Provider value={managersValue}>
            {children}
        </LoginContext.Provider>
    )
}

export const useLoginContext = () => {

    const loginContext = useContext(LoginContext);

    if (!loginContext) {

        throw new Error("useLoginContext must be used within a LoginProvider");
    }

    return loginContext;
}