import React, { useEffect } from 'react';
import { useWindowContext } from '../../../context/WindowContext';
import { useAlertsContext } from '../../../context/AlertsContext';
import { useContainersContext } from '../../../context/ContainersContext';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import RedPin from '../../../images/marker-icon-red.svg';
import YellowPin from '../../../images/marker-icon-yellow.svg';
import GreenPin from '../../../images/marker-icon-green.svg';
import './Localizaçao.css';

const Localizaçao = () => {

    // window context
    const {
        windowSize,
    } = useWindowContext();

    // alerts context (to change color of pin in map if container is full or damaged) 
    const {
        alertsX,
        getAlerts
    } = useAlertsContext();

    const {
        containersX,
        getContainers
    } = useContainersContext();

    useEffect(() => {
        getContainers();
        getAlerts()
    }, [])

    // function to create markers in different colors dependending on state (normal, full or damaged)
    const createMarkers = (container) => {
          if (alertsX.some((alert) => alert.container_id == container.input && alert.act == "damaged" && alert.resolved == false)) {
                return redIcon;
          } else if (alertsX.some((alert) => alert.container_id == container.input && alert.act == "full" && alert.resolved == false)) {
                return yellowIcon;
          } else {
            return greenIcon;
          }
    }

    // config to different color pins
    const redIcon = L.icon({
        iconUrl: RedPin,
        shadowUrl: '',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const yellowIcon = L.icon({
        iconUrl: YellowPin,
        shadowUrl: '',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const greenIcon = L.icon({
        iconUrl: GreenPin,
        shadowUrl: '',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    if (windowSize.width <= 850) {

        if (containersX.requestPending || alertsX.requestPending) {
            return (
                <div className="table__spinner">
                    <span className="spinner-border"></span>
                    <span className="spinner__text">A carregar...</span>
                </div>
            )
        }
        return (<MapContainer
            className='map__container'
            center={[40.644996, -8.643549]}
            zoom={16}
            scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {containersX.map((contLoc) => {
                return (
                    <Marker position={[contLoc.latitude, contLoc.longitude]} icon={createMarkers(contLoc)}>
                        <Popup>ID do contentor: {contLoc.input}</Popup>
                    </Marker>
                )
            })}
        </MapContainer>
        )
    }

        if (containersX.requestPending || alertsX.requestPending) {
            return (
                <div className="table__spinner">
                    <span className="spinner-border"></span>
                    <span className="spinner__text">A carregar...</span>
                </div>
            )
        }
    return (
        <MapContainer
            className='map__container'
            center={[40.6451, -8.64308]}
            zoom={20}
            scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {containersX.map((contLoc) => {
                return (
                    <Marker position={[contLoc.latitude, contLoc.longitude]} icon={createMarkers(contLoc)}>
                        <Popup>ID do contentor: {contLoc.input}</Popup>
                    </Marker>
                )
            })}
        </MapContainer>
    )
}

export default Localizaçao;
