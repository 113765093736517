import React from 'react';
import Alerts from './Alerts';
import './AlertsContent.css';

const AlertsContent = ({ activeTab, date }) => {
 
  return (
    <div id="alerts-content-col">
        <Alerts tabType={activeTab} date={date}/>
    </div>
  );
}

export default AlertsContent;