import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="content-footer">
            <span className="footer-text">Plataforma IoT Smart Biowaste - Copyright © Todos os direitos reservados 2BWEBCONNECT, LDA</span>
        </div>
    )
}

export default Footer;