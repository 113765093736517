import React, { useEffect, useState } from 'react';
import { useSuggestionsContext } from '../../../context/SuggestionsContext';
import { useWindowContext } from '../../../context/WindowContext';
import SuggestionsFilter from './SuggestionsFilter';
import SuggestionsTable from './SuggestionsTable';
import './Sugestoes.css';

const Sugestoes = () => {

 // filter tabs state
 const [tabs, setTabs] = useState([
  { name: "Todas", id: 1, iconColor: "dark" },
  { name: "Sugestões válidas", id: 2, iconColor: "info" },
  { name: "Sugestões descartadas", id: 3, iconColor: "danger" },
  { name: "Sugestões realizadas", id: 4, iconColor: "success"}
]);

  // state for active tab
const [activeTab, setActiveTab] = useState(tabs[0].name);

// show suggestions state
const [buttonClicked, setButtonClicked] = useState(false);

  // set active tab function
const toggle = (tab) => {
  if (activeTab !== tab) setActiveTab(tab);
};

  // window context
  const {
    windowSize,
  } = useWindowContext();

// suggestions context
    const {
        getSuggestions,
    } = useSuggestionsContext();
  
  // get users and suggestions
  useEffect(() => {
    getSuggestions();
  }, []);

  // arrow up or down in "add new container button"
  const renderCaret = () => {  
    return (
    <>
        {!buttonClicked && <i className="fas fa-caret-down"></i>}
        {buttonClicked && <i className="fas fa-caret-up"></i>} 
    </>
    ); 
  }

   // show content (tabs)
   const handleClick = () => {
    setButtonClicked(!buttonClicked);
  }

  const renderSuggestionsTabs = () => {
    if (buttonClicked) {
      return (<SuggestionsFilter tabs={tabs} activeTab={activeTab} toggle={toggle}/>)
    }
  }

  if (windowSize.width <= 850) {
  
    return (
      <div className="suggestions">
        <div className="responsiveSuggestionsMobile">
        <button className="open-tabs-button" onClick={handleClick}> Filtrar sugestões {renderCaret()}</button>
        {renderSuggestionsTabs()}
        </div>
        <label className="suggestions-title-resp">Sugestões</label>
        <SuggestionsTable activeTab={activeTab}/>
      </div>
    )
  }

return (
  <div className="suggestions">
    <div className="sug-tabela">
      <label className="suggestionsTable-title"><i className="fas fa-lightbulb fa-fw"></i> Sugestões feitas pelos utilizadores</label>
      <SuggestionsTable activeTab={activeTab}/>
    </div>
    <div className="sug-filter">
      <SuggestionsFilter tabs={tabs} activeTab={activeTab} toggle={toggle}/>
    </div>
  </div>
  )  
}

export default Sugestoes;