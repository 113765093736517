import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useSuggestionsContext } from '../../../context/SuggestionsContext'
import { useUsersContext } from '../../../context/UsersContext';
import TablePagination from '../../Pagination/TablePagination';
import './SuggestionsTable.css';

const SuggestionsTable = ({activeTab}) => {

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 7;

  // suggestions context
  const {
      suggestionsX,
      seeTheEntireMessage,
      validateSuggestion,
      discardSuggestion,
      resolveSuggestion
  } = useSuggestionsContext();

  // users context
  const {
      usersX,
      getUsers,
    } = useUsersContext();

    useEffect(() => {
      getUsers()
    }, []);

   // filter suggestions in different tabs
   const filterTabs = () => {
    let suggestions;
    if (activeTab == "Todas") {
      suggestions = suggestionsX.filter(suggestion => suggestion.accepted == "0" && suggestion.resolved == "0");
    } else if (activeTab == "Sugestões válidas") {
      suggestions = suggestionsX.filter(suggestion => suggestion.accepted == "1" && suggestion.resolved == "0");
    } else if (activeTab == "Sugestões descartadas") {
      suggestions = suggestionsX.filter(suggestion => suggestion.accepted == "0" && suggestion.resolved == "1");
    } else if (activeTab == "Sugestões realizadas") {
      suggestions = suggestionsX.filter(suggestion => suggestion.accepted == "1" && suggestion.resolved == "1");
    }
    return suggestions;
  };

  // functions to render button "resolve" and th - only for valid suggestions
  const realizedSuggestion = (id) => { 
    if (activeTab == "Sugestões válidas") {
      return (<td data-label="Realizada"><button className="btn sug-buttons" onClick={() => resolveSuggestion(id)}> <i className="fas fa-check-double"></i></button></td>)
    }
  }

  const realizedSuggestionTh = () => {
    if (activeTab == "Sugestões válidas") {
      return (<th scope="col">Realizada</th>)
    }
  }

  // get user names for table
  const getUsersNames = (userId) => {
    if (usersX.requestPending) {
      return [];
    }

    if (usersX.length < 1) return;
    const user = usersX.find(({id}) => id === userId)

    const first_name = user?.first_name;
    const last_name = user?.last_name;
    return (<td data-label="Utilizador" scope="row">{first_name} {last_name}</td>)
  }

  const paginate = (users) => {
    const page = currentPage;
      return [...users].splice(page * pageSize, pageSize);
  }

  //render suggestions list
  const suggestionsList = (filteredSuggestions) => {
    
    return paginate(filteredSuggestions).map(({
        id,
        suggestion,
        user_id,
        created_at,
        seeAllMsg
      }) => {
          return seeAllMsg ?
          
          (
              <>
            <tr key={id} className="suggestions-table-row">
                {getUsersNames(user_id)}
                <td data-label="Nº de utilizador">{user_id}</td>
                <td className="empty-row-sugg"></td>
                <td data-label="Data">{created_at}</td>
                <td data-label="Visualizar sugestão">
                  <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessage(id)}}><i className="fas fa-eye"></i></button>
                </td>
                <td data-label="Aceitar/Descartar">
                    <button className="btn" title="Aceitar" onClick={() => validateSuggestion(id)} disabled={activeTab == "Sugestões válidas" || activeTab == "Sugestões realizadas"}><i className="fas fa-check"></i></button>
                    <button className="btn" title="Descartar" onClick={() => discardSuggestion(id)} disabled={activeTab == "Sugestões descartadas" || activeTab == "Sugestões realizadas"}><i className="fas fa-times"></i></button>
                </td>
                {realizedSuggestion(id)}
                </tr><tr><td className="colspan-style" colSpan={realizedSuggestion() ? "7" : "6"} data-label="Sugestão">{suggestion}</td>
            </tr>
            </>
          ) : (
            <tr key={id} className="suggestions-table-row">
                {getUsersNames(user_id)}
                <td data-label="Nº de utilizador">{user_id}</td>
                <td className="content hideContent">{suggestion}</td>
                <td data-label="Data">{created_at}</td>
                <td data-label="Visualizar sugestão">
                  <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessage(id)}}><i className="fas fa-eye"></i></button>
                </td>
                <td data-label="Aceitar/Descartar">
                    <button className="btn" title="Aceitar" onClick={() => validateSuggestion(id)} disabled={activeTab == "Sugestões válidas" || activeTab == "Sugestões realizadas"}><i className="fas fa-check"></i></button>
                    <button className="btn" title="Descartar" onClick={() => discardSuggestion(id)} disabled={activeTab == "Sugestões descartadas" || activeTab == "Sugestões realizadas"}><i className="fas fa-times"></i></button>
                </td>
                {realizedSuggestion(id)}
            </tr>
          )
      });
    }

    //render suggestions table
  const suggestionsTable = () => {

    if (suggestionsX.requestPending) {
      return (
        <div className="table__spinner">
         <span className="spinner-border"></span>
         <span className="spinner__text">A carregar...</span>
        </div>
      )
    }

    const filteredSuggestions = filterTabs();

    if (!filteredSuggestions.some((suggestion) => suggestion.resolved == 0 && suggestion.accepted == 1) && activeTab == "Sugestões válidas") {
      return (<p>Nenhuma sugestão foi classificada como válida.</p>)
    } else if (!filteredSuggestions.some((suggestion) => suggestion.resolved == 1 && suggestion.accepted == 0) && activeTab == "Sugestões descartadas") {
      return (<p>Nenhuma sugestão foi classificada como descartada.</p>)
    } else if (!filteredSuggestions.some((suggestion) => suggestion.resolved == 1 && suggestion.accepted == 1) && activeTab == "Sugestões realizadas") {
      return (<p>No momento, nenhuma sugestão foi realizada.</p>)
    } else if (filteredSuggestions.length < 1) {
      return (<p>Não há sugestões.</p>)
     }

    return(
        <Table>
        <thead className="table-suggestions-head">
            <tr>
                <th scope="col">Utilizador</th>
                <th scope="col">Nº de utilizador</th>
                <th scope="col">Sugestão</th>
                <th scope="col">Data</th>
                <th scope="col">Visualizar sugestão</th>
                <th scope="col">Aceitar/Descartar</th>
                {realizedSuggestionTh()}
            </tr>
        </thead>

        <tbody className="table-suggestions-body">
            {suggestionsList(filteredSuggestions)}
        </tbody>
        </Table>
    )
    }

    const renderPagination = () => {
      if (suggestionsX.requestPending) {
        return;
      } 
      
      const filteredSuggestions = filterTabs();

      if (filteredSuggestions.length <= pageSize) {
        return;
      }
      return (<TablePagination pageSize={pageSize} numRows={filteredSuggestions.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
    }

    return (
        <div>
          {suggestionsTable()}
          {renderPagination()}
        </div>
    )
}

export default SuggestionsTable;
