import React, {useState, useEffect} from 'react';
import HistoryMenu from './HistoryMenu';
import UserHistoryGraphic from './UserHistoryGraphic';
import { useUserHistoryContext } from '../../../../context/UserHistoryContext';
import { useUsersContext } from '../../../../context/UsersContext';
import { useWindowContext } from '../../../../context/WindowContext';
import { Redirect, withRouter } from 'react-router-dom';

import './Historico.css';

const Historico = ({user, history}) => {
    
    // window context
    const {
        windowSize,
    } = useWindowContext();

    // function to get date (today -- final date) 
    const getDateNow = () => {
        return new Date();
    }

    // function to get date (a month ago -- initial date)
    const lastMonth = () => {
        const initialDate = getDateNow();
        let newDate = new Date(initialDate);
        newDate.setMonth(newDate.getMonth() - 1);
        return newDate;
    }

    // dates state
    const [searchInitialDate, setSearchInitialDate] = useState(lastMonth());
    const [searchFinalDate, setSearchFinalDate] = useState(getDateNow());

    // function to get total weight from search period
     const totalWeight = () =>  {
        if (userHistoryX.requestPending) {
            return;
        }
        let totalWeightInFloat = userHistoryX.reduce((acc, {weight}) => acc += parseFloat(weight), 0).toFixed(2);
        return totalWeightInFloat.toString().replace(".", ",");
    }

    const {
        usersX,
        getUsers
    } = useUsersContext();

    const {
        userHistoryX,
        getUserHistory
    } = useUserHistoryContext();

    useEffect(() => {
        getUsers();
    }, []);

    // show state
    const [buttonClicked, setButtonClicked] = useState(false);

    // show content
   const handleClick = () => {
    setButtonClicked(!buttonClicked);
  }

    // arrow up or down
    const renderCaret = () => {  
        return (
        <>
            {!buttonClicked && <i className="fas fa-caret-down"></i>}
            {buttonClicked && <i className="fas fa-caret-up"></i>} 
        </>
        ); 
    }

    // for mobile - on click of choose period button, render menu
    const renderMenu = () => {
        if (buttonClicked) {
           return <HistoryMenu user={user} getUserHistory={getUserHistory} 
                                windowSize={windowSize} handleClick={handleClick} 
                                searchInitialDate={searchInitialDate} setSearchInitialDate={setSearchInitialDate} 
                                searchFinalDate={searchFinalDate} setSearchFinalDate={setSearchFinalDate}/>
        }
    }

    if (user == null) {
       return( <Redirect to="/dashboard/utilizadores"></Redirect> )
    }

    if (windowSize.width <= 850) {
        
        return (
            <div className="history">
            <h1 className="history__title">Quantidade de resíduos despejados por {user.first_name + " " + user.last_name}</h1>
                <div className="history__menu">
                    <button className="open-tabs-button" onClick={handleClick}>Clica para escolher um período {renderCaret()}</button>
                    {renderMenu()}
                </div>
                <div className="graphic">
                    <p className="graphic__legend">Kilogramas/dia</p>
                    <UserHistoryGraphic userHistoryX={userHistoryX} searchInitialDate={searchInitialDate} searchFinalDate={searchFinalDate}/>
                    <p className="total__weight font-weight-bold">
                        Peso total do período selecionado em kilogramas: {totalWeight()}
                    </p>
                </div>
        </div>
        )
    }

    return (
        <div className="history">
            <h1 className="history__title">Quantidade de resíduos despejados por {user.first_name + " " + user.last_name}</h1>
            <div className="history__content">
                    <div className="graphic">
                        <p className="graphic__legend">Kilogramas/dia</p>
                        <UserHistoryGraphic userHistoryX={userHistoryX} searchInitialDate={searchInitialDate} searchFinalDate={searchFinalDate}/>
                        <p className="total__weight font-weight-bold">
                            Peso total do período selecionado em kilogramas: {totalWeight()}
                        </p>
                    </div>
                <HistoryMenu user={user} getUserHistory={getUserHistory} 
                            windowSize={windowSize} handleClick={handleClick}
                            searchInitialDate={searchInitialDate} setSearchInitialDate={setSearchInitialDate}
                            searchFinalDate={searchFinalDate} setSearchFinalDate={setSearchFinalDate}/>
            </div>
        </div>
    )
}

export default withRouter(Historico);