import React from 'react';
import { useForm } from 'react-hook-form';
import { usePremiosContext } from '../../../context/PremiosContext';

const AddNewPrize = ({setSelectedTab}) => {

   // hook form functions
   const { register, handleSubmit, errors } = useForm();

   //Premios context
   const {
    insertPrize
    } = usePremiosContext();

// submit new prize (add prize)
const onSubmit = (data, event) => {
    insertPrize(event, data.prizeName, data.description, data.file, data.points, data.quantity, data.remaining);
    setSelectedTab("listTab");
};

//render new prize form function
const renderAddNewPrize = () => {
    return (
         <form className="prize-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                 <div className="form-group">
                         <label className="font-weight-bold">Nome do produto</label>
                         <input name="prizeName" type="text" className="form-control" ref={register({required: "Necessário preencher campo 'nome do produto'",
                                                                                        maxLength: { value: 50, message: "Nome do produto demasiado longo. Máximo de 50 caracteres."}})}></input>
                     {errors.prizeName && <p className="prize-form-errors">{errors.prizeName.message}</p>}
                     </div>
 
                     <div className="form-group">
                         <label className="font-weight-bold">Descrição do produto</label>
                         <textarea name="description" type="text" className="form-control" ref={register({required: "Necessário preencher campo 'descrição do produto'",
                                                                                         maxLength: { value: 150, message: "Descrição demasiada longa. Máximo de 150 caracteres."}})}></textarea>
                     {errors.description && <p className="prize-form-errors">{errors.description.message}</p>}
                     </div>
                     
                     <div className="form-group">
                         <label className="font-weight-bold">Pontos</label>
                         <input name="points" type="text" className="form-control" ref={register({required: "Necessário preencher este campo",})}></input>
                     {errors.points && <p className="prize-form-errors">{errors.points.message}</p>}
                     </div>

                     <div className="form-group">
                         <label className="font-weight-bold">Quantidade disponível</label>
                         <input name="quantity" type="text" className="form-control" ref={register({required: "Necessário preencher este campo",})}></input>
                     {errors.quantity && <p className="prize-form-errors">{errors.quantity.message}</p>}
                     </div>

                     <div className="form-group">
                         <label className="font-weight-bold">Adicionar imagem do prémio</label>
                         <input ref={register} name="file" className="form-control" type="file" ref={register({required: "Obrigatório anexar uma imagem",})}></input>
                         {errors.file && <p className="prize-form-errors">{errors.file.message}</p>}
                     </div>

                     <button type="submit" className="btn btn-dark add-button">Adicionar</button>
                     
             </form>
         )
    }

    return (
        <>
            {renderAddNewPrize()}
        </>
    )
}

export default AddNewPrize;