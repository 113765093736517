import React, { createContext, useContext, useState } from "react";
import { useRequestsContext } from './RequestsContext';
import { useLoginContext } from './LoginContext';
import Axios from 'axios';

const PremiosContext = createContext();

export default function PremiosProvider({children}) {

    //Request context
    const {
        requestPrefix
    } = useRequestsContext();

      // Login Context
      const {
        getLoggedManager
    } = useLoginContext();

    const fetchRequest = requestPrefix + "/management-prizes/read-prizes.php";
    const fetchPrizeAlertsRequest = requestPrefix + "/management-prizes/prize-alerts.php";
    const updateRequest = requestPrefix + "/management-prizes/update-prize.php";
    const activateRequest = requestPrefix + "/management-prizes/activate-prize.php";
    const sendPrizeRequest = requestPrefix + "/management-prizes/send-prize.php";
    const deleteRequest = requestPrefix + "/management-prizes/delete-prize.php";
    const insertRequest = requestPrefix + "/management-prizes/add-prize.php";
    const uploadRequest = requestPrefix + "/files/upload-file.php"

const [prizes, setPrizes] = useState({requestPending:true});

const [prizeAlerts, setPrizeAlerts] = useState({requestPending:true});

    // FETCH PRIZES FROM DATABASE
    const fetchPrizes = () => {

        Axios.get(fetchRequest)
            .then(({ data }) => {

                if (data.success === 1) {
                    const prizesData = data.prizes.map(prize => {
                        return {
                        ...prize,
                        prizeName: prize.prize_name,
                        imagePath: prize.image_path,
                        managerId: prize.manager_id
                        }
                    }) 
                    setPrizes(prizesData);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // FETCH PRIZES ALERTS FROM DB
    const fetchPrizeAlerts = () => {
        Axios.get(fetchPrizeAlertsRequest)
            .then(({ data }) => {

                if (data.success === 1) {
                    setPrizeAlerts(data.alerts);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // SEND PRIZE
    const sendPrize = (id, sent) => {

        Axios.post(sendPrizeRequest,
        {
            id: id,
            sent: sent
        })
        .then(({ data }) => {

            if (data.success === 1) {

                let prizeAlertsArr = prizeAlerts.map(prizeAlert => {

                    if (prizeAlert.id === id) {

                        prizeAlert.sent = sent;  
                           
                    }
                    return prizeAlert;

                });

                    setPrizeAlerts(prizeAlertsArr);
                 
                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            }); 
        }
 
    // UPDATE PRIZES
    const handleUpdate = (id, prizeName, description, points, quantity) => {
        Axios.post(updateRequest,
            {
                id: id,
                prizeName: prizeName,
                description: description,
                points: parseInt(points),
                quantity: parseInt(quantity),
                remaining: parseInt(quantity)
               
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    let prizesArr = prizes.map(prize => {

                        if (prize.input === id) {

                            prize.prizeName = prizeName;
                            prize.description = description;
                            prize.points = parseInt(points);
                            prize.quantity = parseInt(quantity);
                            prize.remaining = parseInt(quantity);
                        }

                        return prize;
        
                    });

                    setPrizes(prizesArr);
                 
                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // ACTIVATE/DEACTIVATE PRIZE
    const activatePrize = (id, active) => {
        
        Axios.post(activateRequest,
        {
            id: id,
            active: active
        })  
        .then(({ data }) => {

            if (data.success === 1) {

                let prizesArr = prizes.map(prize => {

                    if (prize.input === id) {

                        prize.active = active;  
                           
                    }
                    return prize;

                });

                    setPrizes(prizesArr);
                 
                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            }); 
        }

    // DELETE PRIZES
    const handleDelete = (id) => {

        let prizesArr = prizes.filter(prize => prize.input !== id);

        Axios.post(deleteRequest,
            {
                id: id
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    setPrizes(prizesArr);

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // INSERT PRIZE
    const insertPrize = (event, prizeName, description, file, points, quantity) => {

        event.preventDefault();
        event.persist();
        
        if (file[0]) {
            uploadImage(file).then(({ data: uploadedData }) => {
                if (uploadedData.success !== 1) {
                    alert(uploadedData.msg);
                    return;
                }  
                Axios({
                    method: "post",
                    url: insertRequest,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    data: {
                        managerId: parseInt(getLoggedManager()),
                        prizeName: prizeName,
                        description: description,
                        imagePath: uploadedData.file,
                        points: parseInt(points),
                        quantity: parseInt(quantity)
                    }
                    })
                    .then(({ data }) => {
                        if (data.success === 1) {
        
                            let prizesArr = [...prizes];
                            prizesArr.unshift({
                                input: data.id,
                                prizeName: prizeName,
                                description: description,
                                image_path: uploadedData.file,
                                points: points,
                                quantity: quantity,
                                remaining: quantity,
                                active: false
                            });
        
                            setPrizes(prizesArr);
        
                            event.target.reset();
        
                        } else {
        
                            alert(data.msg);
                        }
                    })
                    .catch(error => {
        
                        console.log(error);
                    });
            });  
        } 
    }

    const uploadImage = (file) => {
        var formData = new FormData();
        formData.append("file", file[0]);
        return Axios.post(uploadRequest, formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
    }

    const prizesValue = {
        prizesX: prizes,
        prizeAlertsX: prizeAlerts,
        getPrizes: fetchPrizes,
        fetchPrizeAlerts: fetchPrizeAlerts,
        sendPrize: sendPrize,
        updatePrize: handleUpdate,
        deletePrize: handleDelete,
        insertPrize: insertPrize,
        activatePrize: activatePrize
    }

    return (
        <PremiosContext.Provider value={prizesValue}>
            {children}
        </PremiosContext.Provider>
    )
}

export const usePremiosContext = () => {
    const premiosContext = useContext(PremiosContext);
    
    if (!premiosContext) {

        throw new Error("usePremiosContext must be used within a PremiosProvider");
    }

    return premiosContext;
}