import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from 'react-hook-form';

const ChangeAccountPictureModal = ({ managers, getLoggedManager, requestPrefix, updatePicture, toggle}) => {

        // hook form functions
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    if(data.file[0]) {
      updatePicture(getLoggedManager(), data.file); 
      toggle()
    } else {
      alert("Obrigatório anexar uma imagem para que a foto seja alterada.")
    }
  }
    
    return (
        <Modal isOpen={true} modalTransition={{ timeout: 700 }} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()} id="modalHeader"><i className="fas fa-edit"></i>Alterar Foto</ModalHeader>

            <ModalBody id="modalBody">
                <div className="account-foto-content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <img src={requestPrefix + "/" + managers.find(({id}) => id == getLoggedManager()).manager_picture}  alt="Avatar" id="image-to-change"/><br></br>
                        <label className="font-weight-bold">Escolhe um ficheiro: </label>
                        <input ref={register} className="form-control" type="file" name="file"></input><br></br>
                        <button className="btn btn-dark change-image-button" type="submit">Alterar</button>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ChangeAccountPictureModal;