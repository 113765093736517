import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Alertas from './alertas/Alertas';
import Contentores from './contentores/Contentores';
import Sugestoes from './sugestoes/Sugestoes';
import Mensagens from './mensagens/Mensagens';
import Premios from './premios/Premios';
import PrizeStatistics from './premios/PrizeStatistics';
import Localizaçao from './localizaçao/Localizaçao';
import Recolhas from './recolhas/Recolhas';
import UserRouter from './utilizadores/UserRouter';
import './Content.css';

const Content = () => {
    return (
        <div id="content">
            <Switch>
                <Route exact path="/dashboard/" component={Alertas} />
                <Route exact path="/dashboard/contentores" component={Contentores} />
                <Route exact path="/dashboard/localizaçao" component={Localizaçao} />
                <Route path="/dashboard/utilizadores" component={UserRouter} />
                <Route exact path="/dashboard/sugestoes" component={Sugestoes} />
                <Route exact path="/dashboard/mensagens" component={Mensagens} />
                <Route exact path="/dashboard/premios" component={Premios} />
                <Route exact path="/dashboard/premios/estatisticas" component={PrizeStatistics} />
                <Route exact path="/dashboard/recolhas" component={Recolhas} />
                <Route path="/dashboard" component={() => <h1>404 - PAGE NOT FOUND</h1>} />
            </Switch>
        </div>
    );
};

export default Content;