import React from 'react';
import LoginForm from './LoginForm';
import NavLogo from '../../images/NavBarLogo.png';
import ImageMap from 'image-map';
import './Login.css';

const Login = (props) => {
    
    // Call imageMap to use on topnav icon
    ImageMap('img[useMap]');

    return (
        <div id="login-page">

            <div id="top-login">
                <img src={NavLogo} id="top-logo-login" alt="navBarLogo" useMap="#login-logo-map" />
            </div>

            <map name="login-logo-map">
                <area shape="rect"
                    coords="500,129,110,0"
                    alt="smartbiowaste"
                    href="https://smartbiowaste.pt/"
                    target="_blank"
                    id="login-logomap-area" />
            </map>

            <div id="login-content">
                <div id="login-card">
                    <div id="login-card-body">
                        <div id="login-card-header">
                            <h3>Login do Gestor</h3>
                        </div>
                        <LoginForm />
                    </div>

                    <span id="login-card-footer" >
                        <span id="login-card-footer-text" >
                            Caso não se recorde dos seus dados de acesso, contacte
                            <a href="https://www.2bconnect.pt/"
                                target="_blank">
                                <button id="bconnect-link">
                                    2BWebConnect
                                </button>
                            </a>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Login;