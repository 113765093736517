import React from 'react';
import { useCollectionContext } from '../../context/CollectionContext';
import OperatorsList from './OperatorsList';
import AddNewOperator from './AddNewOperator';
import './Operators.css';

const Operators = () =>  {

    const {
        operatorsX,
        getOperators,
        addOperator,
        deleteOperator
    } = useCollectionContext();

    return (
        <div className="operators">
            <AddNewOperator addOperator={addOperator}/>
            <OperatorsList operatorsX={operatorsX} getOperators={getOperators} deleteOperator={deleteOperator}/>
        </div>
    )
}

export default Operators;