import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import Axios from 'axios';

const ContainersContext = createContext();

export default function ContainersProvider({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    const fetchRequest = requestPrefix + "/management-containers/read-containers.php";
    const updateRequest = requestPrefix + "/management-containers/update-container.php";
    const deleteRequest = requestPrefix + "/management-containers/delete-container.php";
    const insertRequest = requestPrefix + "/management-containers/add-container.php";

    const [containers, setContainers] = useState({requestPending:true});

    // FETCH CONTAINERS FROM THE DATABASE
    const fetchContainers = () => {

        Axios.get(fetchRequest)
            .then(({ data }) => {

                if (data.success === 1) {

                    setContainers(data.containers);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // ON EDIT MODE
    const editMode = (id) => {

        let containersArr = containers.map(container => {

            if (container.input === id) {

                container.isEditing = true;

                return container;
            }

            container.isEditing = false;

            return container;
        });

        setContainers(containersArr);
    }

    //CANCEL EDIT MODE
    const cancelEdit = (id) => {

        let containersArr = containers.map(container => {

            if (container.input === id) {

                container.isEditing = false;

                return container;
            }

            return container;
        });

        setContainers(containersArr);
    }

    // UPDATE CONTAINER
    const handleUpdate = (id, lat, lon, sn, container_type) => {
        
        Axios.post(updateRequest,
            {
                id: id,
                lat: lat,
                lon:lon,
                sn: sn,
                container_type: container_type
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    let containersArr = containers.map(container => {

                        if (container.input === id) {

                            container.latitude = lat;
                            container.longitude = lon;
                            container.serial_number = sn;
                            container.container_type = container_type;
                            container.isEditing = false;

                            return container;
                        }

                        return container;
                    });

                    setContainers(containersArr);

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // DELETE CONTAINER
    const handleDelete = (id) => {

        let containersArr = containers.filter(container => container.input !== id);

        Axios.post(deleteRequest,
            {
                id: id
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    setContainers(containersArr);

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // INSERT CONTAINER
    const insertContainer = (event, lat, lon, sn, container_type) => {

        event.preventDefault();
        event.persist();

        Axios.post(insertRequest,
            {
                lat: lat,
                lon: lon,
                sn: sn,
                container_type: container_type
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    let containersArr = [...containers];
                    containersArr.push({
                        input: data.id,
                        container_id: data.id,
                        lat: data.lat,
                        lon: data.lon,
                        sn: data.sn,
                        container_type: data.container_type
                    });

                    setContainers(containersArr);

                    event.target.reset();

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    const containersValue = {
        containersX: containers,
        getContainers: fetchContainers,
        editMode: editMode,
        cancelEdit: cancelEdit,
        updateContainer: handleUpdate,
        deleteContainer: handleDelete,
        insertContainer: insertContainer
    }

    return (
        <ContainersContext.Provider value={containersValue}>
            {children}
        </ContainersContext.Provider>
    )
}

export const useContainersContext = () => {

    const containersContext = useContext(ContainersContext);

    if (!containersContext) {

        throw new Error("useContainersContext must be used within a ContainersProvider");
    }

    return containersContext;
}