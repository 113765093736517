import React, { createContext, useState, useContext } from 'react';
import { useRequestsContext } from './RequestsContext';
import Axios from 'axios';

const CollectionContext = createContext();

export default function CollectionProvider({ children }) {

    // Requests Context
    const {
        requestPrefix
    } = useRequestsContext();

    const fetchRequest = requestPrefix + "/management-collection/get-collections.php";
    const operatorsFetchRequest = requestPrefix + "/management-collection/get-operators.php";
    const addOperatorsRequest = requestPrefix + "/management-collection/add-operator.php";
    const deleteOperatorRequest= requestPrefix + "/management-collection/delete-operator.php";

    const [collections, setCollections] = useState({requestPending:true});
    const [operators, setOperators] = useState({requestPending:true});

    // FETCH DATA FROM THE DATABASE
    const fetchCollections = () => {
        Axios.get(fetchRequest)
            .then(({ data }) => {

                if (data.success === 1) {
                    const quantities = JSON.parse(data.quantity)
                    const collections = data.collections.reduce((acc, collection) => {
                        const entry = acc.find(e => e.container === collection.container)

                        if(entry && new Date(entry.datatime) < new Date(collection.datatime)) {
                            acc[acc.indexOf(entry)] = collection
                        } else {
                            acc.push(collection)
                        }

                        return acc

                    }, []).map((collection) => {
                        return {
                            ...collection, 
                            weight: quantities.find((entry) => entry.containers === collection.container).weight 
                        }
                    })

                    setCollections(collections);

                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // FETCH ALL OPERATORS FROM DB
    const fetchOperators = () => {
        Axios.get(operatorsFetchRequest)
            .then(({ data }) => {

                if (data.success === 1) {

                    setOperators(data.operators);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    // ADD NEW OPERATOR TO DB
    const addOperator = (event, first_name, last_name) => {
        event.preventDefault();
        event.persist();

        Axios.post(addOperatorsRequest,
            {
               name: first_name + " " + last_name
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    fetchOperators();
                    
                } else {

                    alert(data.msg);
                }
            })
    }

    // DELETE OPERATOR FROM DB
    const deleteOperator = (id) => {

        let operatorsArr = operators.filter(operator => operator.input !== id);

        Axios.post(deleteOperatorRequest,
            {
                id: id
            })
            .then(({ data }) => {

                if (data.success === 1) {

                    setOperators(operatorsArr);

                } else {

                    alert(data.msg);
                }
            })
            .catch(error => {

                console.log(error);
            });
    }

    const collectionsValue = {
        collectionsX: collections,
        getCollections: fetchCollections,
        //trashAmountX: trashAmount,
        //getAmount: fetchAmount,
        operatorsX: operators,
        getOperators: fetchOperators,
        addOperator: addOperator,
        deleteOperator: deleteOperator
    }

    return (
        <CollectionContext.Provider value={collectionsValue}>
            {children}
        </CollectionContext.Provider>
    )
}

export const useCollectionContext = () => {

    const collectionContext = useContext(CollectionContext);

    if (!collectionContext) {

        throw new Error("useUsersContext must be used within a UsersProvider");
    }

    return collectionContext;
}