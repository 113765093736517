import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useUsersContext } from '../../../../context/UsersContext';
import TablePagination from '../../../Pagination/TablePagination';
import './Utilizadores.css';

const Utilizadores = ({history, setUser}) => {

 const [currentPage, setCurrentPage] = useState(0);
 const pageSize = 9;

  // users context
  const {
    usersX,
    getUsers,
  } = useUsersContext();

  // get users on update
  useEffect(() => {

    getUsers();
  }, []);

  //function to volunteer (boolean) appear as 'yes' or 'no' on the table
  const volunteerBooleanToString = (volunteer) => {
    if (volunteer == false) {
       return "não"
    } else {
        return "sim"
    }
}

  // onClick events
  const onClickEvents = (user) => {
    setUser(user)
    history.push("/dashboard/utilizadores/historico")
  }

  // render users list
  const usersList = () => {
    
    return paginate(usersX).map((
    {
      id,
      first_name,
      last_name,
      nif,
      points,
      email,
      address,
      phone_number,
      volunteer,
      container_id,
      created_at
    }) => {
      
    return (
      <tr key={id}>
        <td scope="row" data-label="Nome">{first_name} {last_name}</td>
        <td data-label="NIF">{nif}</td>
        <td data-label="Morada">{address}</td>
        <td data-label="Email">{email}</td>
        <td data-label="Contacto">{phone_number}</td>
        <td data-label="Voluntário">{volunteerBooleanToString(volunteer)}</td>
        <td data-label="Nº de pontos">{points}</td>
        <td data-label="ID do contentor">{container_id}</td>
        <td data-label="Histórico" className="userHistory-link"> <a onClick={() => onClickEvents({id, first_name, last_name})}>Clica aqui</a></td>
        <td data-label="Data">{created_at}</td>
      </tr>
    )
  });
}

const paginate = (users) => {
  const page = currentPage;
    return [...users].splice(page * pageSize, pageSize);
}

  // render users table
  const usersTable = () => {

    if(usersX.requestPending) {
      return (
        <div className="table__spinner">
         <span className="spinner-border"></span>
         <span className="spinner__text">A carregar...</span>
        </div>
      )
    }

    if (usersX.length === 0) {

      return (
        <p>Não há utilizadores registados.</p>
      )
    }

  return(
    <Table>
      <thead className="table-management-users-head">
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">NIF</th>
          <th scope="col">Morada</th>
          <th scope="col">Email</th>
          <th scope="col">Contacto</th>
          <th scope="col">Voluntário</th>
          <th scope="col">Nº de pontos</th>
          <th scope="col">ID do Contentor</th>
          <th scope="col">Histórico</th>
          <th scope="col">Data</th>
        </tr>
      </thead>

      <tbody className="table-management-users-body">
          {usersList()}
      </tbody>
    </Table>
   
  )
}

const renderPagination = () => {
  if (usersX.requestPending) {
    return;
  } if (usersX.length <= pageSize) {
    return;
  }
  return (<TablePagination pageSize={pageSize} numRows={usersX.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
}

  return (
    <div className="table-manag-users">
      <label className="usersTable-title"><i className="fas fa-user-check fa-fw"></i> Utilizadores registados</label>
      {usersTable()}
      
      {renderPagination()}
    
    </div>
  )  
}

export default withRouter(Utilizadores);