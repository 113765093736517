import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import { useLoginContext } from '../../context/LoginContext';
import './LoginForm.css';

const LoginForm = (props) => {

    // hook form functions
    const { register, handleSubmit, errors, setError, clearErrors } = useForm();

    // login context
    const {
        managerData,
        setManagerData,
        managers,
        getManagers,
        setLoggedManager
    } = useLoginContext();

    // submit function
    const onSubmit = (data) => {

        setManagerData(
            {
                email: data.email,
                password: sha256(data.password).toString()
            }
        );
    };

    // get managers from database
    useEffect(() => {
        getManagers();
    }, []);

    // Validate email and password input 
    useEffect(() => {

        if (managerData) {

            managers.forEach(manager => {

                if (manager.manager_email === managerData.email
                    && manager.manager_password === managerData.password
                ) {

                    props.history.push("/dashboard");
                    setLoggedManager(manager);

                    return;
                }
            })

            setError("submit", {
                type: "manual",
                message: "Dados de acesso incorretos"
            });
        }
    }, [managerData]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="input-group form-group">
                <label className="login-input-icon">
                    <i className="fas fa-user" />
                </label>
                <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="form-control"
                    ref={
                        register({
                            required: "Email necessário",
                            pattern: { value: /^\S+@\S+\.\S+$/i, message: "Email inválido" }
                        })
                    }
                    onFocus={() => clearErrors("submit")}
                />
            </div>
            {errors.email && <p className="login-errors">{errors.email.message}</p>}

            <div className="input-group form-group">
                <label className="login-input-icon">
                    <i className="fas fa-key" />
                </label>
                <input
                    name="password"
                    type="password"
                    placeholder="Senha"
                    className="form-control"
                    ref={
                        register({
                            required: "Password necessária",
                            minLength: { value: 8, message: "Password demasiado curta" }
                        })
                    }
                    onFocus={() => clearErrors("submit")}
                />
            </div>
            {errors.password && <p className="login-errors">{errors.password.message}</p>}

            <div id="login-check-submit-row">
                <div id="login-memorize" >
                    <input type="checkbox" id="login-memorize-checkbox" />
                    <label htmlFor="login-memorize-checkbox" id="login-memorize-text">Memorizar dados de acesso</label>
                </div>

                <input name="submit" type="submit" value="Iniciar Sessão" id="login-submit" />
            </div>

            <span id="login-submit-error-row">
                {errors.submit && <p className="login-errors" id="login-submit-error">{errors.submit.message}</p>}
            </span>
        </form>
    );
};

export default withRouter(LoginForm);