import React, { useState, useEffect } from 'react';
import { Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useAlertsContext } from '../../../context/AlertsContext';
import { useUsersContext } from '../../../context/UsersContext';
import TablePagination from '../../Pagination/TablePagination';
import './Alerts.css';
import SpecialAlerts from './SpecialAlerts';

const Alerts = ({ history, tabType, date }) => {

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 7;
  
  // alerts context
  const {
    alertsX,
    getAlerts,
    seeTheEntireMessage,
    seeTheEntireMessageSpecialAlerts,
    specialAlerts,
    readSpecialAlerts,
    resolveAlert,
    resolveSpecialAlert,
  } = useAlertsContext();

  // user context - to get users info
  const {
    usersX,
    getUsers
  } = useUsersContext();

   // get users function
  useEffect(() => {
    getUsers();
    getAlerts();
  }, []);

const [imagePath, setImagePath] = useState(null);

const alertType = (tabType) => {
  switch(tabType) {
    case "Contentores danificados":
      return "damaged";
    case "Contentores cheios":
      return "full";
  }
}

  // function to filter the alerts
  const filterAlerts = (tabType) => {
    let alerts;
    if(tabType === "Alertas resolvidos" ) {

      alerts = alertsX.filter(alert => alert.resolved === "1");

    } else if (tabType !== "Todos" ) {

      alerts = alertsX.filter(alert => alert.act === alertType(tabType));

    } else if (tabType === "Todos") {

      alerts = alertsX.filter(alert => alert.resolved === "0");

    }

    if(date !== null) {
      return alerts.filter(alerts => new Date(alerts.datatime) > date);
    }

    return alerts;
  };

 // modal state, toggle modal
 const [modal, setModal] = useState(false);
 const toggle = () => setModal(!modal);

 const toggleImg = (img) => {
   setImagePath(img);
   toggle();
}

 //render attachment (image file) when click button
 const modalImage = () => {

  return (
    <div>
    <Modal isOpen={modal} modalTransition={{ timeout: 700 }} toggle={toggle} className="modalTest">
      <ModalHeader toggle={toggle} id="modalHeader">Imagem anexada pelo utilizador</ModalHeader>
          <ModalBody id="modalBody">
            <img src={"https://www.smartbiowaste.pt/" + imagePath} width="300" height="300" alt="Imagem enviada pelo utilizador"/>
          </ModalBody>
    </Modal>
    </div>
  )
}

  //function to put the name of the users in the table instead of the id
  const getUsersNames = (userId) => {
    if (usersX.requestPending) {
      return [];
    }
    if (usersX.length < 1) return;
    const user = usersX.find(({id}) => id === userId)

    const first_name = user?.first_name;
    const last_name = user?.last_name;
    return (<td data-label="Utilizador" scope="row">{first_name} {last_name}</td>)
  }

  //function to resolved (boolean) appear as 'yes' or 'no' on the table
  const booleanToString = (resolved) => {
    if (resolved == false) {
      return "não"
    } else {
        return "sim"
    }
  }

 // change act from english to portuguese
  const translate = (act) => {
    switch (act) {
        case "damaged":
          return "Avaria"
        case "full":
          return "Recolha"
        default: 
          return act
    }
  }

  const paginate = (filteredAlerts) => {
    const page = currentPage;
      return [...filteredAlerts].splice(page * pageSize, pageSize);
  }

  // render alerts
  const alertsList = (filteredAlerts) => {

    return paginate(filteredAlerts).map((
      {
        id,
        datatime,
        act,
        container_id,
        message,
        img,
        user_id,
        resolved,
        seeAllMsg
      }) => {
        
        return seeAllMsg ?
        (
          <>
          <tr key={id} className="table-alerts-body">
              {getUsersNames(user_id)}
              <td data-label="Alerta">{translate(act)}</td>
              <td data-label="ID do contentor">{container_id}</td>
              <td className="emptyrow-alerts"></td>
              <td data-label="Data">{datatime}</td>
              <td data-label="Resolvido">{booleanToString(resolved)}</td>
              <td data-label="Ações">
                <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessage(id)}}><i className="fas fa-eye"></i></button>
                <button type="button" className="btn" title="ver anexo" onClick={() => toggleImg(img)}  disabled={img == null}><i className="fas fa-image"></i></button>
                <button type="button" className="btn" title="resolver alerta" onClick={() => resolveAlert(id)} disabled={resolved == true}><i className="fas fa-check"></i></button>
              </td>
          </tr>
          <tr><td className="colspan-style" colSpan="7" data-label="Mensagem">{message}</td></tr>
          </>
        ) : (
          <tr key={id} className="table-alerts-body">
              {getUsersNames(user_id)}
              <td data-label="Alerta">{translate(act)}</td>
              <td data-label="ID do contentor">{container_id}</td>
              <td className="content hideContent">{message}</td>
              <td data-label="Data">{datatime}</td>
              <td data-label="Resolvido">{booleanToString(resolved)}</td>
              <td data-label="Ações">
                <button type="button" className="btn" title="visualizar" onClick={() => {seeTheEntireMessage(id)}}><i className="fas fa-eye"></i></button>
                <button type="button" className="btn" title="ver anexo" onClick={() => toggleImg(img)} disabled={img == null}><i className="fas fa-image"></i></button>
                <button type="button" className="btn" title="resolver alerta" onClick={() => resolveAlert(id)} disabled={resolved == true}><i className="fas fa-check"></i></button>
              </td>
          </tr>
        )
      });
  } 

  // render alerts table
  const alertsTable = () => {
    if(alertsX.requestPending) {
      return (
        <div className="table__spinner">
        <span className="spinner-border"></span>
        <span className="spinner__text">A carregar...</span>
        </div>
      )
    }

    const filteredAlerts = filterAlerts(tabType);

    if (!filteredAlerts.some((alert) => alert.resolved == 1) && tabType == "Alertas resolvidos") {
      return (
        <p>Não há alertas resolvidos.</p>
      )
    }

    if (filteredAlerts.length < 1) {

      return (
        <p>Não há alertas.</p>
      )
    }

  return(
    <Table>
      <thead className="table-alerts-head">
        <tr>
          <th scope="col">Utilizador</th>
          <th scope="col">Alerta</th>
          <th scope="col">ID do contentor</th>
          <th scope="col">Mensagem</th>
          <th scope="col">Data</th>
          <th scope="col">Resolvido</th>
          <th scope="col">Ações</th>
        </tr>
      </thead>

      <tbody>
          {alertsList(filteredAlerts)}
      </tbody>
    </Table>
  )
}

const renderPagination = () => {
  if (alertsX.requestPending) {
    return;
  } 
  
  const filteredAlerts = filterAlerts(tabType);
  
  if (filteredAlerts.length <= pageSize) {
    return;
  }
  return (<TablePagination pageSize={pageSize} numRows={filteredAlerts.length} currentPage={currentPage} setCurrentPage={setCurrentPage}/>)
}

  switch(tabType) {
    case "Recolhas especiais":
    case "Rec. especiais realizadas":
      return (
        <SpecialAlerts tabType={tabType} seeTheEntireMessageSpecialAlerts={seeTheEntireMessageSpecialAlerts}
                       resolveSpecialAlert={resolveSpecialAlert} specialAlerts={specialAlerts} 
                       readSpecialAlerts={readSpecialAlerts} />
      )
    default:
      return (
        <div className="alerts-list">
              <div>
                {alertsTable()}   
                {renderPagination()}
              </div>
            {modalImage()}
          </div>
        )
  }
} 

export default Alerts;